// import axios from "axios";

// export const logIn = (data) => async (dispatch) => {
//   dispatch({ type: "LOGIN_PENDING", payload: "" });
//   try {
//     const resp = await axios.post(
//       "profile-service-mongo/api/ProfileEntity/v2/login",
//       data
//     );

//     dispatch({ type: "LOGIN_FULFILLED", payload: resp.data });
//     localData();
//     getMMUAssociation();
//     // return false;
//   } catch (error) {
//     dispatch({ type: "LOGIN_REJECTED", payload: error.response?.data });
//     // return false;
//   }
//   async function localData() {
//     var username = JSON.parse(localStorage.getItem("mmu-auth")).username;
//    // console.log("17", 'JSON.parse(localStorage.getItem("mmu-auth")).username');
//     await axios
//       .get(
//         `aggregation-service/api/Aggregation/v1/getProjectByProjectTeam?projectteams.profileentity.username=${username}`
//       )
//       .then((response) => { 
//         console.log("20", response);

//         localStorage.setItem(
//           "project-Detail",
//           JSON.stringify(response.data.data.projectAggregation)
//         );
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   async function getMMUAssociation() {
//     // setLoading(true);
//     var profileId = JSON.parse(localStorage.getItem("mmu-auth")).profileid;
//     await axios
//       .get(

//         `mmu-association-service/api/MMUAssociation/v1/getAllByProfileId/${profileId}`
//       )
//       .then((response) => {
//         getComponet()
//         console.log(response.data.data.dtoList);
//         localStorage.setItem(
//           "association",
//           JSON.stringify(response.data.data.dtoList)
//         );
       
//         // setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   async function getComponet() {
//     // setLoading(true);
//     var rolecode = JSON.parse(localStorage.getItem("mmu-auth")).rolecode;
//     await axios
//       .get(

//         `menu-service/api/Menu/v1/get?rolecode=${rolecode}&resourcecode=${process.env.REACT_APP_rescoursecode}`
//       )
//       .then((response) => {
//         console.log(response.data.data.dtoList);
//         localStorage.setItem(
//           "componet",
//           JSON.stringify(response.data.data.dtoList)
//         );
       
//         // setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
// };



import axios from "axios";

export const logIn = (data) => async (dispatch) => {
  dispatch({ type: "LOGIN_PENDING", payload: "" });
  try {
    const resp = await axios.post(
      "profile-service-mongo/api/ProfileEntity/v2/login",
      data
    );

    dispatch({ type: "LOGIN_FULFILLED", payload: resp.data });
    localData();
    getMMUAssociation();
    // return false;
  } catch (error) {
    dispatch({ type: "LOGIN_REJECTED", payload: error.response?.data });
    // return false;
  }
  async function localData() {
    var username = JSON.parse(localStorage.getItem("mmu-auth")).username;
   // console.log("17", 'JSON.parse(localStorage.getItem("mmu-auth")).username');
    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/getProjectByProjectTeam?projectteams.profileentity.username=${username}`
      )
      .then((response) => {
        console.log("20", response);

        localStorage.setItem(
          "project-Detail",
          JSON.stringify(response.data.data.projectAggregation)
        );
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getMMUAssociation() {
    // setLoading(true);
    var profileId = JSON.parse(localStorage.getItem("mmu-auth")).profileid;
    await axios
      .get(

        `mmu-association-service/api/MMUAssociation/v1/getAllByProfileId/${profileId}`
      )
      .then((response) => {
        getComponet()
        console.log(response.data.data.dtoList);
        localStorage.setItem(
          "association",
          JSON.stringify(response.data.data.dtoList)
        );
       
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getComponet() {
    // setLoading(true);
    var rolecode = JSON.parse(localStorage.getItem("mmu-auth")).rolecode;
    await axios
      .get(

        `menu-service/api/Menu/v1/get?rolecode=${rolecode}&resourcecode=${process.env.REACT_APP_rescoursecode}`
      )
      .then((response) => {
        console.log(response.data.data.dtoList);
        localStorage.setItem(
          "componet",
          JSON.stringify(response.data.data.dtoList)
        );
       
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
};