import * as React from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//EDit button

import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import swal from 'sweetalert';
import {
  MdDelete,
  MdOutlineLibraryAdd,
  MdOutlinePreview,
  MdPreview,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import MmuCreationView from "./MmuCreationView";
import { object } from "prop-types";

function MmuList() {
  const comp = "SM";
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);

  const [AssignEquipmentvalue, setAssignEquipmentvalue] = React.useState(null);

  const [quantity, setQuantity] = useState("0");
  const [assetNo, setAssetNo] = useState("");
  let [QuantityId, setQuantityId] = useState("0");
  const [QTY, setQTY] = useState("");
  const [asset, setAsset] = useState("");
  const [selectmap, setSelectMap] = useState("");
  const [selectvech, setSelectVec] = useState("");
  const [selectassi, setSelectAssi] = useState(null);

  const [VehicleAssignList, setVehicleAssignList] = useState("");
  const [AssignEquipmentList, setAssignEquipmentList] = useState("");
  const [mapwareList, setmapwareList] = useState("");
  const [raspberryList, setRasberryList] = useState("");
  const [selectraspberry, setSelectRasberry] = useState("");
  const updateMMUObject = {
    classname: "",
    code: "",
    createdby: "",
    createddate: "",
    description: description,
    documentofproof: "",

    id: 0,
    latlong: "",
    location: null,
    modifiedby: "",

    name: Updatename,
    recstatus: "",
    status: "",
    syncstatus: "",
    tempid: "",
    raspberry: null,
    vehicle: null,
    warehouse: null,
    equipmentlist: [],
  };
  const [updateObject, setUpdateObject] = useState(updateMMUObject);
  function setAllUpdateObject(object) {
   // updateObject.description = object.description;
    updateObject.name = object.name;
    updateObject.classname = object.classname;
    updateObject.code = object.code;
    updateObject.createdby = object.createdby;
    updateObject.createddate = object.createddate;

    updateObject.documentofproof = object.documentofproof;

    updateObject.id = object.id;

    updateObject.location = object.location;
    updateObject.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;

    updateObject.recstatus = object.recstatus;
    updateObject.status = object.status;
    updateObject.syncstatus = object.syncstatus;
    updateObject.tempid = object.tempid;
    updateObject.vehicle = object.vehicle;
    updateObject.warehouse = object.warehouse;
    updateObject.equipmentlist = object.equipmentlist;
    updateObject.raspberry=object.raspberry;
  }

  const [inputData, setInputData] = useState({
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  });
  const mmuObj = {
    sapcode: "",
    name: "",
    inputQty: "",
    inputSerialNo: "",
    action: "",
  };
  const equipmentModel = {
    assetno: "",
    item: "",
    quantity: "",
  };

  const [equipment, setEquipment] = useState();
  const [equipmentList, setEquipmentList] = useState([]);
  const dataObj = {
    columns: [
      { Header: "sapcode", accessor: "item.sapcode", width: "25%" },
      { Header: "Equiment Name", accessor: "item.name" },
      { Header: "Qty", accessor: "quantity", width: "12%" },
      { Header: "Asset No", accessor: "assetno" },
      { Header: "base url", accessor: "action" },
      { Header: "mac address", accessor: "" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };

  const [tableData, setTableData] = useState(dataObj);

  const [givenQty, setGivenQty] = useState();
  const [select, setSelect] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);

  // Map Ware-house
  const defaultPropssetMapWarehouse = {
    options: !mapwareList ? [{ name: "Loading...", id: 0 }] : mapwareList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  // vechicle assigned
  const defaultPropssetVehicleAssign = {
    options: !VehicleAssignList
      ? [{ name: "Loading...", id: 0 }]
      : VehicleAssignList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  // Assign Equipment
  const defaultPropssetAssignEquipment = {
    options: !AssignEquipmentList
      ? [{ name: "Loading...", id: 0 }]
      : AssignEquipmentList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropssetRaspberryAssign = {
    options: !raspberryList ? [{ name: "Loading...", id: 0 }] : raspberryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function getmapwareData() {
    await axios
      .get(
        `warehouse-master-service/api/WareHouseMaster/v1/get`
      )
      .then((response) => {
        setmapwareList(response.data.data.dtoList);
        getVehicleData(response.data?.data?.dtoList);
        // setLoading(false);
        // console.log("====================================");
        // console.log("de", response);
        // console.log("====================================");
      })

      .catch((e) => {
        // console.log("error", e);
      });
  }

  async function getVehicleData(mapwarelist) {
    await axios
      .get(
        `item-master-service/api/itemMaster/v1/get?type=VEHICLE`
      )
      .then((response) => {
        setVehicleAssignList(response.data.data.dtoList);
        getAssignEquipmentData(mapwarelist, response.data?.data?.dtoList);
        // setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
      await axios
      .get(
        `item-master-service/api/itemMaster/v1/get?type=RASPBERRY-PI`
      )
      .then((response) => {
        setRasberryList(response.data.data.dtoList);
    
        
        // console.log("====================================");
        // console.log("de", response);
        // console.log("====================================");
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getAssignEquipmentData(mapwarehouselist, Vechiclelist) {
    await axios
      .get(
        `item-master-service/api/itemMaster/v1/get?type=EQUIPMENT`
      )
      .then((response) => {
        setAssignEquipmentList(response.data.data.dtoList);
        getData(mapwarehouselist, Vechiclelist, response.data?.data?.dtoList);
        // setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  const removeItem = function (id) {
    const objWithIdIndex = tableData.rows.findIndex(
      (obj) => obj.item.id === id
    );
    console.log("index", objWithIdIndex);

    tableData.rows.splice(objWithIdIndex, 1);
    dataObj.rows = tableData.rows;
    setTableData(dataObj);

    updateObject.equipmentlist = tableData.rows;

    console.log("finalTable", tableData);
    console.log("updateObject", updateObject);
  };

  const addDataObj = (
    assetno = assetNo,
    equipObj = selectassi,
    qty = quantity
  ) => {
    const equipmentobj = {
      assetno: assetno,
      item: equipObj,
      quantity: qty,
      action: (
        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => removeItem(equipmentobj.item?.id)}
          />
        </Link>
      ),
    };
    console.log(equipmentobj);

    let filter = tableData.rows.filter(
      (a) => a.item.id == equipmentobj.item.id
    );

    if (filter.length < 1) {
      tableData.rows.push(equipmentobj);
      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      updateObject.equipmentlist = tableData.rows;
      console.log("table", tableData);
      console.log("updateObject", updateObject);
    }
  };

  async function submitHandle() {
    console.log("sub", tableData);
  }

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  async function onUpdatedata(object) {
    dataObj.rows = [];
    console.log("ObjectRes", object);

    setSelectMap(object.warehouse);
    setSelectVec(object.vehicle);
    setSelectRasberry(object.raspberry)
    if (object.equipmentlist != null) {
      for (let i = 0; i < object.equipmentlist.length; i++) {
        addDataObj(
          object.equipmentlist[i].assetno,
          object.equipmentlist[i].item,
          object.equipmentlist[i].quantity
        );
      }
    }

    setItem(object);

    setUpdatename(object.name);
    setDescription(object.description);
    setAllUpdateObject(object);

    console.log("updateObject", updateObject);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    // if (window.confirm("Are you sure that you want to delete that user record"))
    //   await axios
    //     .put(
    //       "https://apithf.softwaremathematics.com/mmu-creation-service/api/MMUCreation/v1/delete",
    //       item
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       getData();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this mmu record!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          console.log(willDelete)
          if (willDelete) {
                axios
            .put(
              'mmu-creation-service/api/MMUCreation/v1/delete',
              item
            )
                .then((response) => {
              console.log(response);
              getData();
            })
            swal("Poof! Your mmu record has been deleted!", {
              icon: "success",
              
            });
          } else {
            swal("Your mmu record is safe!");
          }
        });


  }
  async function updateData() {
    updateObject.name = Updatename;
    updateObject.description = description;
    for (let i = 0; i < updateObject.equipmentlist.length; i++) {
      delete updateObject.equipmentlist[i].action;
    }
    console.log("final", updateObject);
    await axios
      .put(
        "mmu-creation-service/api/MMUCreation/v1/update",
        updateObject
      )
      .then((response) => {
        setOpen(false);
        getmapwareData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name" },
        { Header: "code", accessor: "code" },
        { Header: "base url", accessor: "baseurl" },
        { Header: "raspberry name", accessor: "resname" },
       // { Header: "description", accessor: "description" },
        { Header: "createddate", accessor: "createddateV2" },
        { Header: "status", accessor: "recstatuscolor" },
       
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        `mmu-creation-service/api/MMUCreation/v1/get`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          if (item.recstatus === "APPROVED") {
            item.recstatuscolor = (
              <span style={{ color: "green" }}>{item.recstatus}</span>
            );
          } else if (item.recstatus === "REJECTED") {
            item.recstatuscolor = (
              <span style={{ color: "RED" }}>{item.recstatus}</span>
            );
          } else if (item.recstatus === "OPEN") {
            item.recstatuscolor = (
              <span style={{ color: "BLUE" }}>{item.recstatus}</span>
            );
          } else {
            item.recstatuscolor = (
              <span style={{ color: "grey" }}>{item.recstatus}</span>
            );
          }
          item.baseurl=item.raspberry?.attributes?.baseurl
          item.resname=item.raspberry?.attributes?.name

          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item);
                  }}
                />
              </Link>

              <Link
                to={{
                  pathname: "/pages/users/mmu-view",
                }}
                state={{
                  itemId: item.id,
                  dataObject: JSON.stringify(item),
                  equipmentList: JSON.stringify(item.equipmentlist),
                }}
              >
                <MdOutlinePreview style={iconStyle} />
              </Link>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.createddateV2 = new Date(item.createddate).toDateString();
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getmapwareData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    MMU LIST
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/users/mmu-Creation"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update MMU List</DialogTitle>
        <DialogContent style={{ width: 550, height: 390 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              variant="standard"
              fullWidth
              value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
          <Autocomplete
                {...defaultPropssetRaspberryAssign}
                value={selectraspberry}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setSelectRasberry(newValue);

                  updateObject.raspberry = newValue;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                   
                    variant="standard"
                    label="RaspberryAssign"
                  />
                )}
              />
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssetMapWarehouse}
                value={selectmap}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setSelectMap(newValue);
                  updateObject.warehouse = newValue;

                  // values.mapware = newValue;
                  console.log("537", selectmap);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="mapware" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssetVehicleAssign}
                value={selectvech}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setSelectVec(newValue);
                  updateObject.vehicle = newValue;

                  // values.VehicleAssign = newValue;
                  console.log("559", selectvech);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="VehicleAssign"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssetAssignEquipment}
                value={selectassi}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setSelectAssi(newValue);
                  console.log("366", selectassi);

                  // values.AssignEquipment = newValue?.name;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="AssignEquipment"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDInput
                name="quantity"
                type="number"
                label="Quantity"
                placeholder="Quantity"
                onChange={(e) => {
                  // addEquipmentFromAsset(AssignEquipmentvalue, e.target.value);
                  setQuantity(e.target.value);
                  // AssignEquipmentvalue.assetno = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDInput
                name="assetNo"
                type="text"
                label="AssetNo"
                placeholder="AssetNo"
                onChange={(e) => {
                  // addEquipmentFromAsset(AssignEquipmentvalue, e.target.value);
                  setAssetNo(e.target.value);
                  // AssignEquipmentvalue.assetno = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={() => addDataObj()}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}
export default MmuList;
