import * as Yup from "yup";
import checkout from "layouts/pages/nurseregistration/new-nurseregistration/schemas/form";

const {
  formField: {
    mobileno,
    name,
    // mmunumber,
    // village,
    address,
    age,
   
    // gender,
  //  marital,
    // education,
    // occupation,
    // rationcard,
    // disability,
    // comdoc,
    docno,
    name2,
    mobileno2,
    address2,
    
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [mobileno.name]: Yup.string()
    .required(mobileno.errorMsg)
    .min(10, mobileno.invalidMsg)
    .max(10, mobileno.invalidMsg),
  
    [name.name]: Yup.string().required(name.errorMsg),
    // [mmunumber.name]: Yup.string().required(mmunumber.errorMsg),
    // [village.name]: Yup.string().required(village.errorMsg),
    [address.name]: Yup.string().required(address.errorMsg),
    [age.name]: Yup.string().required(age.errorMsg),
    // [gender.name]: Yup.string().required(gender.errorMsg),
    // [marital.name]: Yup.string().required(marital.errorMsg),
    // [education.name]: Yup.string().required(education.errorMsg),
    // [occupation.name]: Yup.string().required(occupation.errorMsg),
    // [marital.name]: Yup.string().required(marital.errorMsg),
    // [rationcard.name]: Yup.string().required(rationcard.errorMsg),
    
    // [disability.name]: Yup.string().required(disability.errorMsg),
    // [comdoc.name]: Yup.string().required(comdoc.errorMsg),
    // [docno.name]: Yup.string().required(docno.errorMsg),
    // [mobileno2.name]: Yup.string()
    //   .required(mobileno2.errorMsg)
    //   .min(10, mobileno2.invalidMsg)
    //   .max(10, mobileno2.invalidMsg),
    
    // [name2.name]: Yup.string().required(name2.errorMsg),
    // [address2.address]: Yup.string().required(name2.errorMsg),
  }),
  Yup.object().shape({
  
    
 
  }),
 
];

export default validations;
