import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit, FaRegTimesCircle } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd, MdOutlinePreview } from "react-icons/md";
import { Link } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

const IndentManagement = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [opencreate, setOpencreate] = React.useState(false);
  const [color] = useState("#344767");
  const [itemList, setItemList] = useState("");
  const [diseaseValue, setDiseaseValue] = useState(null);
  const [itemValue, setItemValue] = useState(null);
  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);
  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")));
  const [mmuvalue, setMmuvalue] = useState(null);
  const [val, setVal] = useState([]);

  const [uom, setUom] = useState("");
  const [qty, setQty] = useState("");


  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };
  const handleClosecreate = () => {
    console.log("dsfsdfs");
    setOpencreate(false);
  };

  const handlechange = () => {

  }
  const removeRow = (i) => {
    const dltval = [...val]
    dltval.splice(i, i)
    setVal(dltval)
  }
  const dataObj = {
    columns: [
      { Header: "Request id", accessor: "mrnumber" },

      { Header: "submitted by", accessor: "createdby" },
      { Header: "status", accessor: "recstatus" },
      { Header: "Request date", accessor: "fcreateddate" },
      { Header: "comments", accessor: "comment" },
      { Header: "view", accessor: "action" }
    ],
    rows: [],
  };
  const dataObj2 = {
    columns: [
      { Header: "S.NO", accessor: "name" },
      { Header: "Medicine Description", accessor: "uom" },

      { Header: "uom", accessor: "itemtype" },
      { Header: "Oty", accessor: "mmucode" },

    ],
    rows: [],
  };

  const dataObjMed = {
    columns: [
      // { Header: "S.NO", accessor: "name" },
      { Header: "Medicine Description", accessor: "name" },

      { Header: "uom", accessor: "uom" },
      { Header: "Oty", accessor: "quantityrqst" },
      { Header: "remove", accessor: "action" }

    ],
    rows: [],
  };

  const [tableDatas, setTableDatas] = useState(dataObj);
  const [tableDatas2, setTableDatas2] = useState(dataObj2);
  const [tableDatasMed, setTableDatasMed] = useState(dataObjMed);


  const defaultPropsprojectMMU = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsItem = {
    options: !itemList
      ? [
        { name: "Loading...", sapcode: "X" }
      ]
      : itemList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const removeItem = function (uniqueKey) {

    const objWithIdIndex = tableDatasMed.rows.findIndex((obj) => obj.sapitemcode === uniqueKey);
    tableDatasMed.rows.splice(objWithIdIndex, 1);
    setTableDatasMed(tableDatasMed);
    // values.EquipmentList = tableDatasMed.rows;
  };

  const handleSubmitIndent = () => {

    for (let i = 0; i < tableDatasMed.rows.length; i++) {
      delete tableDatasMed.rows[i].action
    }
    console.log("154", tableDatasMed.rows)
    createMedRequesition(tableDatasMed.rows)
    setOpencreate(false)
  }
  const handleadd = (item) => {
    const objectMedReq = {
      createdby:
        loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname,
      itemtype: item.type,

      mmucode: mmucode,
      mmuid: "",
      mmuitemcode: "",
      mmuname: "",
      name: item.name,
      quantityrqst: item.quantity,
      recstatus: "OPEN",
      remarks: "",
      sapitemcode: item.sapcode,
      status: "ACTIVE",
      syncstatus: "SYNCED",
      tempid: "",
      uom: item.uom,

      action: (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeItem(objectMedReq?.sapitemcode)}
            />
          </Link>
        </span>
      ),
    };

    let filter = tableDatasMed.rows.filter(
      (a) => a.sapitemcode == objectMedReq.sapitemcode
    );

    if (filter.length < 1) {
      tableDatasMed.rows.push(objectMedReq);
      dataObjMed.rows = tableDatasMed.rows;
      setTableDatasMed(dataObjMed);
    }
    console.log("163", objectMedReq);
  }



  function getLineItemView(ObjectList) {
    const dataObj2 = {
      columns: [
        // { Header: "S.NO", accessor: "nme", width: "2%" },
        { Header: "Medicine Description", accessor: "name" },
        { Header: "uom", accessor: "uom" },
        { Header: "Oty", accessor: "quantityrqst" },


      ],
      rows: ObjectList,

    };
    setTableDatas2(dataObj2);
  }
  async function getMedRequisitionData(mmucode) {
    const dataObj = {
      columns: [
        { Header: "Request id", accessor: "mrnumber" },

        { Header: "submitted by", accessor: "createdby" },
        { Header: "status", accessor: "recstatus" },
        { Header: "Request date", accessor: "fcreateddate" },
        { Header: "comments", accessor: "comment" },
        { Header: "view", accessor: "action" }
      ],
      rows: [],
    };



    await axios
      .get(
        `med-requisition-gen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?mmucode=${mmucode}`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {
          item.medrequisitiongentxndto.fcreateddate = new Date(item.medrequisitiongentxndto?.createddate).toDateString()
          if (item.medrequisitiongentxndto.comment == null) {
            item.medrequisitiongentxndto.comment = "NA"
          }
          item.medrequisitiongentxndto.action = (
            <span>
              <Link to={{}}>
                <MdOutlinePreview
                  style={iconStyle}
                  onClick={() => {
                    setOpen(true);
                    getLineItemView(item.medrequisitiongendtoList)
                  }}
                />
              </Link>


            </span>
          );
          dataObj.rows.push(item.medrequisitiongentxndto)
          // dataObj2.rows.push(item.medrequisitiongendtoList);
          // item.description = (

          //   <Grid item xs={12} sm={12}>
          //     <Autocomplete
          //       style={{ width: "168px" }}
          //       {...defaultPropsDisease}
          //       value={diseaseValue}
          //       onChange={(event, newValue) => {
          //         // handleChange(event);
          //         setDiseaseValue(newValue);

          //         values.diseasetype = newValue?.name;

          //       }}
          //       renderInput={(params) => (
          //         <TextField{...params} label="select-item"
          //         />
          //       )}
          //     />
          //   </Grid>
          // );
          // item.qty = (
          //   <MDInput
          //     type="number"
          //     variant="outlined"
          //     size="small"

          //     style={{ width: "70px" }}
          //   />
          // );

        });
        // console.log("res142", dataObj2);
        // setTableDatas2(dataObj2);
        setTableDatas(dataObj)

      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getItemData() {
    await axios
      .get(
        `item-master-service/api/itemMaster/v1/get`
      )
      .then((response) => {
        setItemList(response.data.data.dtoList)

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function createMedRequesition(objectList) {
    await axios
      .post(
        `med-requisition-gen-service/api/MedRequisitionGen/v1/createList`,
        objectList
      )
      .then((response) => {
        getMedRequisitionData(mmucode);
        getItemData()
        // setItemList(response.data.data.dtoList)

      })
      .catch((e) => {
        getMedRequisitionData(mmucode);
        getItemData()
        console.log("error", e);
      });
  }

  async function getindentmangement(mmucode) {
    console.log("88", mmucode);

    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "ptient id", accessor: "name" },

        { Header: "Prescription Id", accessor: "uom" },
        { Header: "current stock", accessor: "currentstock" },
        { Header: "Doctor", accessor: "itemtype" },
        { Header: "test date", accessor: "mmucode" },

        { Header: "view and update", accessor: "action" }
      ],
      rows: [],
    };


    await axios
      .get(
        `patient-service/api/patient/v1/get?mmucode=${mmucode}`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <MdOutlinePreview
                  style={iconStyle}
                  onClick={() => {
                    setOpen(true);
                    getindentpopupdata();

                  }}
                />
              </Link>


            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getMedRequisitionData(mmucode);
    getItemData()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Indent-Management
                  </MDTypography>
                </span>

                <Grid container spacing={3}>

                  <Grid item xs={12} sm={10}>
                    <MDButton
                      circular="true"
                      color="success"
                      size="small"
                      style={{
                        marginLeft: "527px",
                        marginTop: "0px"
                      }}
                      variant="gradient"
                      onClick={() => { setOpencreate(true) }} >
                      Create New
                    </MDButton>
                  </Grid>
                </Grid>



              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      {/* ==================View Page============= */}
      <Dialog open={open} onClose={handleClose}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={10}  >
            <DialogTitle style={{ textAlign: "center" }}>Indent Management</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: "12px" }}  >
            <span>
              <Link to={{}}>
                <CancelIcon
                  style={iconStyle}
                  fontSize="meduim"
                  onClick={handleClose}
                />
              </Link>

            </span>
          </Grid>
        </Grid>

        <DialogContent style={{ width: "750px", height: 250 }}>

          <DataTable table={tableDatas2} showTotalEntries={false} entriesPerPage={false} canSearch={false} />

          {/* <Grid item xs={12} sm={4} style={{ marginLeft: "130px" }}>
            <MDButton
              variant="gradient"
              color="info"
              size='small'
              type="submit"

            >
              Cancel
            </MDButton>
          </Grid> */}


          {/* <Grid style={{ marginTop: "12px", marginLeft: "5px" }}  >
            <MDInput style={{ width: "384px" }} label="Comment by doctor..." />
          </Grid> */}
        </DialogContent>

      </Dialog>

      <Dialog open={opencreate} onClose={handleClosecreate}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={10}  >
            <DialogTitle style={{ textAlign: "center" }}>Create Indent Request</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: "12px" }}  >
            <span>
              <Link to={{}}>
                <CancelIcon
                  style={iconStyle}
                  fontSize="large"
                  onClick={handleClosecreate}
                />
              </Link>

            </span>
          </Grid>
        </Grid>

        <DialogContent style={{ height: 620 }}>

          <MDBox mt={1.625}>
            <Grid container spacing={3} >
              <Grid item xs={12} sm={6}  >
                <Autocomplete
                  // style={{ width: "168px" }}
                  {...defaultPropsItem}
                  value={itemValue}
                  onChange={(event, newValue) => {
                    // handleChange(event);
                    setItemValue(newValue);
                    setUom(newValue?.uom)
                    // values.diseasetype = newValue?.name;

                  }}
                  renderInput={(params) => (
                    <TextField{...params} label="select-item"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}  >
                <MDInput
                  disabled={true}
                  type="text"
                  variant="outlined"
                  label="UOM"
                  value={uom}
                />
              </Grid>
              <Grid item xs={12} sm={2}  >
                <MDInput
                  type="number"
                  variant="outlined"
                  label="Quantity"
                  value={qty}
                  onChange={(e) => {
                    setQty(e.target.value)
                    itemValue.quantity = e.target.value
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}  >
                <MDButton
                  circular="true"
                  color="success"
                  size="small"
                  variant="gradient"
                  onClick={() => { handleadd(itemValue) }}

                >
                  Add
                </MDButton>
              </Grid>
            </Grid>
            <MDBox mt={1.625}>
              <DataTable table={tableDatasMed} showTotalEntries={false} entriesPerPage={false} canSearch={false} />
            </MDBox>
          </MDBox>



          <Grid style={{ textAlign: "center", marginTop: "8px" }}>
            <MDButton
              circular="true"
              color="success"
              size="small"
              variant="gradient"
              onClick={handleSubmitIndent}
            >
              Submit Indent
            </MDButton>
          </Grid>
        </DialogContent>

      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};

export default IndentManagement;
