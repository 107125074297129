// import React from "react";

// // @mui material components
// import Card from "@mui/material/Card";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// // @mui material components
// import Grid from "@mui/material/Grid";
// // Material Dashboard 2 PRO React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// // Data
// // import dataTableData from "layouts/applications/data-tables/data/dataTableData";
// import { useEffect, useState } from "react";
// // import { getRoles } from "@testing-library/react";
// import MDButton from "components/MDButton";
// import axios from "axios";
// import { BarLoader } from "react-spinners";
// // import { item } from "examples/Sidenav/styles/sidenavItem";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FaRegEdit } from "react-icons/fa";
// import { MdCheckBox, MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
// import SearchIcon from "@mui/icons-material/Search";
// import { Link, useNavigate } from "react-router-dom";

// import Button from "@mui/material/Button";

// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";

// import DialogTitle from "@mui/material/DialogTitle";
// import { Autocomplete, Checkbox, TextField } from "@mui/material";

// //date time picker;
// import DateTimePicker from "react-datetime-picker";

// function ApprovedScheduler() {
//   const comp = "SM";
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [color] = useState("#344767");
//   const [enddate, setEnddate] = useState(new Date());
//   const [address, setAddress] = useState("");
//   const [startdate, setStartdate] = useState(new Date());
//   const [title, setTitle] = useState("");
//   const [Updatename, setUpdatename] = useState("");
//   const [description, setDescription] = useState("");
//   const [err, setErr] = useState("");
//   const [item, setItem] = useState({});
//   const [open, setOpen] = React.useState(false);
//   const [defaultMMU, setDefaultMMU] = useState();
//   const [mmucode, setMmucode] = useState("");
//   const [mmuList, setMmuList] = useState("");
//   const [updateObject, setUpdateObject] = useState();
//   const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
//   const [checkusers, setcheckeduser] = useState([]);
//   const [eventList, setEventList] = useState([]);

//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     flexDirection: "column",
//     marginRight: "10px",
//   };

//   const handleChange = (e, resEventList) => {
//     console.log("73", e.target);
//     const { name, checked } = e.target;
//     console.log("name", name);
//     console.log("checked", checked);
//     console.log("eventList", resEventList);
//     if (name === "allSelect") {
//       let tempEvent = resEventList.map((event) => {
//         return { ...event, isChecked: checked };
//       });
//       // setEventList(tempEvent);
//       dataObj.rows = tempEvent;
//     } else {
//       let tempEvent = resEventList.map((event) => {
//         event.title === name ? { ...event, isChecked: checked } : event;
//         if (event.title === name) {
//           console.log(event);
//         }
//       });
//       console.log(tempEvent);
//       // setEventList(tempEvent);
//       dataObj.rows = tempEvent;
//       setTableDatas(dataObj);
//     }
//   };
//   const handleChangeV2 = (e, resEventList = eventList) => {
//     console.log("73", e.target);
//     const { name, checked } = e.target;
//     console.log("name", name);
//     console.log("checked", checked);
//     console.log("eventList", resEventList);
//     if (name === "allSelect") {
//       for (let i = 0; i < resEventList.length; i++) {
//         resEventList[i].checkbox.props.checked = true;
//         resEventList[i].isChecked = true;
//       }
//     } else {
//       for (let i = 0; i < resEventList.length; i++) {
//         if (resEventList[i].title === name) {
//           resEventList[i].checkbox.props.checked = true;
//           resEventList[i].isChecked = true;
//         }
//       }
//     }
//     console.log(resEventList);
//     // tableDatas.rows = resEventList;
//     // dataObj.rows = tableDatas.rows;
//     // setTableDatas(dataObj);
//   };

//   const dataObj = {
//     columns: [
//       // {
//       //   Header: (
//       //     <h1>
//       //       <input
//       //         type="checkbox"
//       //         style={iconStyle}
//       //         name="allSelect"
//       //         checked={!eventList.some((event) => event?.isChecked !== true)}
//       //         onChange={handleChangeV2}
//       //         class="form-check-input"
//       //       ></input>
//       //     </h1>
//       //   ),

//       //   accessor: "checkbox",
//       //   width: "25%",
//       // },
//       { Header: "title", accessor: "title", width: "25%" },
//       { Header: "address", accessor: "finalAddress", width: "30%" },
//       { Header: "start date", accessor: "startdate" },
//       { Header: "end date", accessor: "enddate" },
//       { Header: "action", accessor: "action", width: "12%" },
//     ],
//     rows: [],
//   };

//   const [tableDatas, setTableDatas] = useState(dataObj);
//   const defaultPropsMMU = {
//     options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
//     getOptionLabel: (option) => option.name,
//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };

//   async function onUpdatedata(object) {
//     setTitle(object.title);
//     setAddress(object.address);
//     setEnddate(new Date(object.enddate));
//     setStartdate(new Date(object.startdate));
//     setUpdateObject(object);
//     setOpen(true);
//   }

//   const handleClose = () => {
//     console.log("dsfsdfs");
//     setOpen(false);
//   };

//   async function getMMUData() {
//     await axios
//       .get(
//         `https://apithf.softwaremathematics.com/mmu-creation-service/api/MMUCreation/v1/get`
//       )
//       .then((response) => {
//         setMmuList(response.data.data.dtoList);
//         console.log("179",localStorage.getItem("mmu-code"))
//         if (response.data.data.dtoList.length > 0) {
//           var mmucode="";
//           console.log("3", response.data.data.dtoList[0]);
//           if(localStorage.getItem("mmu-code")!==null){

//             setDefaultMMU(JSON.parse(localStorage.getItem("mmu-code")));
//             mmucode=JSON.parse(localStorage.getItem("mmu-code")).code;
//           }
//           else{
//             setDefaultMMU(response.data.data.dtoList[0]);
//             mmucode=response.data.data.dtoList[0].code
//           }

//           setMmucode(mmucode);
//           getData(mmucode);
//           // setLoading(false);
//         }
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   async function onDelete(item, mmu) {
//     if (
//       window.confirm("Are you sure that you want to delete that user record")
//     ) {
//       delete item.action;
//       await axios
//         .put(
//           "https://apithf.softwaremathematics.com/event-service/api/event/v1/delete",
//           item
//         )
//         .then((response) => {
//           console.log("del", response.data.data.dto);
//           console.log("de", defaultMMU);
//           console.log("dem", mmucode);
//           getData(response.data.data.dto.mmucode);
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     }
//   }
//   async function updateEvent(updateObject, recstatus, color) {
//     console.log("up", updateObject);
//     updateObject.recstatus = recstatus
//     updateObject.documentofproof = color;
//     updateObject.modifiedby =
//       JSON.parse(localStorage.getItem("mmu-auth")).firstname +
//       " " +
//       JSON.parse(localStorage.getItem("mmu-auth")).lastname;
//     updateObject.approvedby = JSON.parse(localStorage.getItem("mmu-auth")).firstname +
//       " " +
//       JSON.parse(localStorage.getItem("mmu-auth")).lastname;
//     delete updateObject.action;
//     // // delete updateObject.action;
//     // console.log("upFin", updateObject);
//     if (
//       window.confirm("Are you sure that you want to Approved this record")
//     ) {
//     await axios
//       .put(
//         "https://apithf.softwaremathematics.com/event-service/api/event/v1/update",
//         updateObject
//       )
//       .then((response) => {
//         setOpen(false);
//        window.location.reload();
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }}

//   function getData(mmucode) {
//     // setLoading(true);
//     console.log("code 143", mmucode);

//     axios
//       .get(
//         `https://apithf.softwaremathematics.com/event-service/api/event/v1/get?mmucode=${mmucode}&recstatus=OPEN`
//       )
//       .then((response) => {
//         // dataObj.rows = [];

//         response.data.data.dtoList.map((item) => {
//           item.action = (
//             <span>
//               <MDButton
//                 variant="gradient"
//                 color="success"
//                 size="small"
//                 onClick={() =>

//                   updateEvent(item, "APPROVED", "#2db551")
//                 }
//               >
//                 Approve
//               </MDButton>
//               <MDButton
//                 variant="gradient"
//                 color="error"
//                 size="small"
//                 style={{ marginLeft: "5px" }}
//                 onClick={() =>

//                   updateEvent(item, "REJECTED", "#b52d2d")
//                 }

//               >
//                 Reject
//               </MDButton>

//             </span>
//           );
//           item.isChecked = false;
//           // item.checkbox = (
//           //   <input
//           //     type="checkbox"
//           //     style={iconStyle}
//           //     name={item.title}
//           //     checked={item.isChecked}
//           //     onChange={(e) => handleChangeV2(e, response.data.data.dtoList)}
//           //     class="form-check-input"
//           //   ></input>
//           // );
//           console.log(item.pincode, item);
//           if (typeof item.pincode !== "undefined" && item.pincode !== null) {
//             item.finalAddress =
//               item.pincode?.village?.name +
//               " (" +
//               item.pincode?.pincode +
//               ") " +
//               item.pincode?.subdistrict?.name +
//               " " +
//               item.pincode?.district?.name +
//               " " +
//               item.pincode?.state?.name;
//           }
//           dataObj.rows.push(item);
//         });
//         setEventList(response.data.data.dtoList);
//         console.log("res70", dataObj);
//         // tableDatas.rows = dataObj.rows;
//         setTableDatas(dataObj);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   useEffect(() => {
//     // getData();
//     getMMUData();
//   }, []);
//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       {!loading && (
//         <MDBox pt={3}>
//           <MDBox>
//             <Card>
//               <MDBox pl={3} pr={3} lineHeight={1}>
//                 <div style={addButtonStyle}>
//                   <span
//                     style={{
//                       alignSelf: "flex-start",
//                       marginTop: "20px",
//                     }}
//                   >
//                     <MDTypography variant="h5" fontWeight="medium">
//                       Approved Scheduler List
//                     </MDTypography>
//                   </span>

//                   <Grid container spacing={3} mt={4}>
//                     <Grid item xs={12} sm={3}>
//                       <Autocomplete
//                         defaultValue={defaultMMU}
//                         disablePortal
//                         id="combo-box-demo"
//                         sx={{ width: 280 }}
//                         {...defaultPropsMMU}
//                         onChange={(event, newValue) => {
//                           getData(newValue.code);
//                           setMmucode(newValue.code);
//                           localStorage.setItem('mmu-code', JSON.stringify(newValue))
//                         }}
//                         renderInput={(params) => (
//                           <TextField {...params} label="MMU" />
//                         )}
//                       />
//                     </Grid>
//                     {/* <Grid
//                       item
//                       xs={2}
//                       sm={3}
//                       style={{ fontSize: "18px", marginLeft: "10px" }}
//                     >
//                       <MDBox mb={2}>
//                         <label style={{ color: "grey" }}>
//                           Select Start Date
//                         </label>
//                         <DateTimePicker
//                           onChange={setStartdate}
//                           value={startdate}
//                         />
//                       </MDBox>
//                     </Grid> */}

//                     {/* <Grid
//                       item
//                       xs={2}
//                       sm={3}
//                       style={{ fontSize: "18px", marginLeft: "10px" }}
//                     >
//                       <MDBox mb={2}>
//                         <label style={{ color: "grey" }}>
//                           Select End Date{" "}
//                         </label>
//                         <DateTimePicker onChange={setEnddate} value={enddate} />
//                       </MDBox>
//                     </Grid> */}
//                     <Grid
//                       item
//                       xs={2}
//                       sm={1}
//                       style={{ fontSize: "30px", marginLeft: "10px" }}
//                     >
//                       <MDBox mt={2}>
//                         {/* <Link to={{}}>
//                           <SearchIcon />
//                         </Link> */}
//                       </MDBox>
//                     </Grid>
//                   </Grid>
//                   {/* <Link
//                     style={{
//                       alignSelf: "flex-end",
//                       marginRight: "10px",
//                       marginTop: "-23px",
//                       color: "#344767",
//                     }}
//                     to={"/mmu/component/shceduler"}
//                   >
//                     <MdOutlineLibraryAdd disable="true" />
//                   </Link> */}
//                 </div>
//               </MDBox>
//               <BarLoader
//                 color={color}
//                 loading={loading}
//                 aria-label="Loading Spinner"
//               />
//               <DataTable table={tableDatas} canSearch={true} />
//             </Card>
//           </MDBox>
//         </MDBox>
//       )}
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Update Event</DialogTitle>
//         <DialogContent style={{ width: 550, height: 400 }}>
//           <MDBox mb={2}>
//             <MDInput
//               type="text"
//               label="Title"
//               variant="standard"
//               fullWidth
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//             />
//           </MDBox>
//           <MDBox mb={2}>
//             <MDInput
//               type="text"
//               label="Address"
//               variant="standard"
//               fullWidth
//               value={address}
//               onChange={(e) => setAddress(e.target.value)}
//             />
//           </MDBox>
//           <MDBox mb={2}>
//             <label>Select Start Date </label>
//             <DateTimePicker onChange={setStartdate} value={startdate} />
//           </MDBox>
//           <MDBox mb={2}>
//             <label>Select End Date </label>
//             <DateTimePicker onChange={setEnddate} value={enddate} />
//           </MDBox>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={updateEvent}>Submit</Button>
//         </DialogActions>
//       </Dialog>
//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default ApprovedScheduler;

import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
import MDButton from "components/MDButton";
import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdCheckBox, MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

//date time picker;
import DateTimePicker from 'react-datetime-picker';

import moment from "moment-timezone";

function ApprovedScheduler() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [enddate, setEnddate] = useState(new Date());
  const [address, setAddress] = useState("");
  const [startdate, setStartdate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const [defaultMMU, setDefaultMMU] = useState();
  const [mmucode, setMmucode] = useState("");
  const [mmuList, setMmuList] = useState([]);
  const [updateObject, setUpdateObject] = useState();
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [checkusers, setcheckeduser] = useState([]);
  const [eventList, setEventList] = useState([]);
  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  function convertTZ(date) {
    var format = "YYYY-MM-DDTHH:mm:ss.sssZ";
    var df =  moment(date, format).tz("Asia/Kolkata").format(format);
    return new Date(df).toLocaleDateString() + " " + tConvert(new Date(df).toLocaleTimeString());
}
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const handleChange = (e, resEventList) => {
    console.log("73", e.target);
    const { name, checked } = e.target;
    console.log("name", name);
    console.log("checked", checked);
    console.log("eventList", resEventList);
    if (name === "allSelect") {
      let tempEvent = resEventList.map((event) => {
        return { ...event, isChecked: checked };
      });
      // setEventList(tempEvent);
      dataObj.rows = tempEvent;
    } else {
      let tempEvent = resEventList.map((event) => {
        event.title === name ? { ...event, isChecked: checked } : event;
        if (event.title === name) {
          console.log(event);
        }
      });
      console.log(tempEvent);
      // setEventList(tempEvent);
      dataObj.rows = tempEvent;
      setTableDatas(dataObj);
    }
  };
  const handleChangeV2 = (e, resEventList = eventList) => {
    console.log("73", e.target);
    const { name, checked } = e.target;
    console.log("name", name);
    console.log("checked", checked);
    console.log("eventList", resEventList);
    if (name === "allSelect") {
      for (let i = 0; i < resEventList.length; i++) {
        resEventList[i].checkbox.props.checked = true;
        resEventList[i].isChecked = true;
      }
    } else {
      for (let i = 0; i < resEventList.length; i++) {
        if (resEventList[i].title === name) {
          resEventList[i].checkbox.props.checked = true;
          resEventList[i].isChecked = true;
        }
      }
    }
    console.log(resEventList);
    // tableDatas.rows = resEventList;
    // dataObj.rows = tableDatas.rows;
    // setTableDatas(dataObj);
  };

    useEffect(() => {
    axios
      .get(
        `mmu-creation-service/api/MMUCreation/v1/get`
      )
      .then((response) => {
        // setrolelist(response.data.data.dtoList);
        setMmuList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, []);

  const dataObj = {
    columns: [
      // {
      //   Header: (
      //     <h1>
      //       <input
      //         type="checkbox"
      //         style={iconStyle}
      //         name="allSelect"
      //         checked={!eventList.some((event) => event?.isChecked !== true)}
      //         onChange={handleChangeV2}
      //         class="form-check-input"
      //       ></input>
      //     </h1>
      //   ),

      //   accessor: "checkbox",
      //   width: "25%",
      // },
      { Header: "title", accessor: "title" },
      { Header: "address", accessor: "finalAddress" },
      { Header: "flag", accessor: "flag"},
      { Header: "start date", accessor: "startdateformated" },
      { Header: "end date", accessor: "enddateformated" },
      { Header: "action", accessor: "action" },
    ],
    rows: [],
  };

  const [tableDatas, setTableDatas] = useState(dataObj);
  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option.name + "(" + option.vehicle?.name + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onUpdatedata(object) {
    setTitle(object.title);
    setAddress(object.address);
    setEnddate(new Date(object.enddate));
    setStartdate(new Date(object.startdate));
    setUpdateObject(object);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  // async function getMMUData() {
  //   await axios
  //     .get(
  //       `https://apithf.softwaremathematics.com/mmu-creation-service/api/MMUCreation/v1/get`
  //     )
  //     .then((response) => {
  //       setMmuList(response.data.data.dtoList);
  //       console.log("179",localStorage.getItem("mmu-code"))
  //       if (response.data.data.dtoList.length > 0) {
  //         var mmucode="";
  //         console.log("3", response.data.data.dtoList[0]);
  //         if(localStorage.getItem("mmu-code")!==null){

  //           setDefaultMMU(JSON.parse(localStorage.getItem("mmu-code")));
  //           mmucode=JSON.parse(localStorage.getItem("mmu-code")).code;
  //         }
  //         else{
  //           setDefaultMMU(response.data.data.dtoList[0]);
  //           mmucode=response.data.data.dtoList[0].code
  //         }

  //         setMmucode(mmucode);
  //         getData(mmucode);
  //         // setLoading(false);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // }

  async function onDelete(item, mmu) {
    if (
      window.confirm("Are you sure that you want to delete that user record")
    ) {
      delete item.action;
      await axios
        .put(
          "event-service/api/event/v1/delete",
          item
        )
        .then((response) => {
          console.log("del", response.data.data.dto);
          console.log("de", defaultMMU);
          console.log("dem", mmucode);
          getData(response.data.data.dto.mmucode);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  async function updateEvent(updateObject, recstatus, color) {
    console.log("up", updateObject);
    updateObject.recstatus = recstatus;
    updateObject.documentofproof = color;
    updateObject.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    updateObject.approvedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    delete updateObject.action;
    // // delete updateObject.action;
    // console.log("upFin", updateObject);
    if (window.confirm("Are you sure that you want to Approved this record")) {
      await axios
        .put(
          "event-service/api/event/v1/update",
          updateObject
        )
        .then((response) => {
          setOpen(false);
          getData(response.data.data.dto.mmucode);
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function getData(mmucode) {
    setLoading(true);
    console.log("code 143", mmucode);

    axios
      .get(
        `event-service/api/event/v1/get?mmucode=${mmucode}&recstatus=OPEN`
      )
      .then((response) => {
        dataObj.rows = [];

        response.data.data.dtoList.map((item) => {
          // item.startdateformated=(startdate.toDateString());

          item.startdateformated=convertTZ(item.startdate);
          item.enddateformated=convertTZ(item.enddate);
          item.action = (
            <span>
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => {
                  updateEvent(item, "APPROVED", "#2db551");

                  // getData(JSON.parse(localStorage.getItem("mmu-code")).code);
                }}
              >
                Approved
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                size="small"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  updateEvent(item, "REJECTED", "#b52d2d");
                }}
              >
                Reject
              </MDButton>
            </span>
          );
          item.isChecked = false;
          // item.checkbox = (
          //   <input
          //     type="checkbox"
          //     style={iconStyle}
          //     name={item.title}
          //     checked={item.isChecked}
          //     onChange={(e) => handleChangeV2(e, response.data.data.dtoList)}
          //     class="form-check-input"
          //   ></input>
          // );
          console.log(item.pincode, item);
          if (typeof item.pincode !== "undefined" && item.pincode !== null) {
            item.finalAddress =
              item.pincode?.village?.name +
              " (" +
              item.pincode?.pincode +
              ") " +
              item.pincode?.subdistrict?.name +
              " " +
              item.pincode?.district?.name +
              " " +
              item.pincode?.state?.name;
          }

          dataObj.rows.push(item);
        });
        setEventList(response.data.data.dtoList);
        console.log("res70", dataObj);
        // tableDatas.rows = dataObj.rows;
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  // useEffect(() => {
  //   // getData();
  //   getMMUData();
  // }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Approved Scheduler List
                  </MDTypography>
                </span>

                <Grid container spacing={3} mt={4}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      defaultValue={defaultMMU}
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsMMU}
                      onChange={(event, newValue) => {
                        getData(newValue.code);
                        setMmucode(newValue.code);
                        localStorage.setItem(
                          "mmu-code",
                          JSON.stringify(newValue)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="MMU" />
                      )}
                    />
                  </Grid>
                  {/* <Grid
                      item
                      xs={2}
                      sm={3}
                      style={{ fontSize: "18px", marginLeft: "10px" }}
                    >
                      <MDBox mb={2}>
                        <label style={{ color: "grey" }}>
                          Select Start Date
                        </label>
                        <DateTimePicker
                          onChange={setStartdate}
                          value={startdate}
                        />
                      </MDBox>
                    </Grid> */}

                  {/* <Grid
                      item
                      xs={2}
                      sm={3}
                      style={{ fontSize: "18px", marginLeft: "10px" }}
                    >
                      <MDBox mb={2}>
                        <label style={{ color: "grey" }}>
                          Select End Date{" "}
                        </label>
                        <DateTimePicker onChange={setEnddate} value={enddate} />
                      </MDBox>
                    </Grid> */}
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    style={{ fontSize: "30px", marginLeft: "10px" }}
                  >
                    <MDBox mt={2}>
                      {/* <Link to={{}}>
                          <SearchIcon />
                        </Link> */}
                    </MDBox>
                  </Grid>
                </Grid>
                {/* <Link
                    style={{
                      alignSelf: "flex-end",
                      marginRight: "10px",
                      marginTop: "-23px",
                      color: "#344767",
                    }}
                    to={"/mmu/component/shceduler"}
                  >
                    <MdOutlineLibraryAdd disable="true" />
                  </Link> */}
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Event</DialogTitle>
        <DialogContent style={{ width: 550, height: 400 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Title"
              variant="standard"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Address"
              variant="standard"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <label>Select Start Date </label>
            <DateTimePicker onChange={setStartdate} value={startdate} />
          </MDBox>
          <MDBox mb={2}>
            <label>Select End Date </label>
            <DateTimePicker onChange={setEnddate} value={enddate} />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateEvent}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default ApprovedScheduler;
