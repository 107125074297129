import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function FormField({ label, name, ...rest }) {
  const shouldValidate = name !== "docno" || (rest.value !== "" && rest.value !== null);

  return (
    <MDBox mb={1.5}>
      <Field {...rest} name={name} as={MDInput} variant="standard" label={label} fullWidth />
      {shouldValidate && (
        <MDBox mt={0.75}>
          <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
            <ErrorMessage name={name} />
          </MDTypography>
        </MDBox>
      )}
    </MDBox>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]) // Adjust the prop type according to your use case
};

export default FormField;
