import { useEffect, useState } from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";
import axios from "axios";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
// item page components
import FormField from "layouts/pages/item/new item/componets/FormField";

import { BarLoader } from "react-spinners";

function ItemInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [value, setValue] = React.useState(null);
  const [attributes, setAttributes] = useState({ keyList: [] });
  const [attFlag, setAttFlag] = useState(false);
  const [uomvalue, setUomValue] = React.useState(null);
  const [packageValue, setPackageValue] = useState(null);
  const [itemList, setItemList] = useState("");
  const [uomlist, setUomlist] = useState("");
  const [packagelist, setPackagelist] = useState("");

  const { formField, values, errors, touched } = formData;

  const defaultProps = {
    options: !itemList ? [{ name: "Loading...", id: 0 }] : itemList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsuom = {
    options: !uomlist ? [{ name: "Loading...", id: 0 }] : uomlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsPackage = {
    options: !packagelist ? [{ name: "Loading...", id: 0 }] : packagelist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const {itemtype, sapcode, uom } = formField;
  var {
    itemame: itemameV,

    sapcode: sapcodeV,
    uom: uomV,
  } = values;

  async function getData() {
    await axios
      .get(
        `type-master-service/api/TypeMaster/v1/get?category=ITEM`
      )
      .then((response) => {
        setItemList(response.data.data.dtoList);
        console.log("44", response.data.data.dtoList);
        // setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `type-master-service/api/TypeMaster/v1/get?category=UOM`
      )
      .then((response) => {
        setUomlist(response.data.data.dtoList);
        console.log("44", response.data.data.dtoList);
        // setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `type-master-service/api/TypeMaster/v1/get?category=PACKAGE`
      )
      .then((response) => {
        setPackagelist(response.data.data.dtoList);
        console.log("44", response.data.data.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Item Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultProps}
                value={value}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setValue(newValue);
                  attributes.keyList = newValue.attributes;
                  values.attributes = attributes;
                  setAttributes(attributes);
                  if (newValue.attributes == null) {
                    setAttFlag(false);
                  } else {
                    setAttFlag(true);
                  }

                  values.itemtype = newValue?.name;
                  console.log("118", newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.itemtype && touched.itemtype}
                    success={itemtype.length > 0 && !errors.itemtype}
                    variant="standard"
                    label={itemtype.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={sapcode.type}
                label={sapcode.label}
                name={sapcode.name}
                value={sapcodeV}
                placeholder={sapcode.placeholder}
                error={errors.sapcode && touched.sapcode}
                success={sapcodeV.length > 0 && !errors.sapcode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsPackage}
                value={packageValue}
                onChange={(event, newValue) => {
                  setPackageValue(newValue);

                  values.package = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Package Type"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsuom}
                value={uomvalue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setUomValue(newValue);

                  values.uom = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.uom && touched.uom}
                    success={uom.length > 0 && !errors.uom}
                    variant="standard"
                    label={uom.label}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {attFlag &&
              attributes.keyList.map((att) => (
                <Grid item xs={12} sm={3}>
                  <FormField
                    type="text"
                    label={att}
                    name={att}
                    value={attributes[att]}
                    placeholder={att}
                  />
                </Grid>
              ))}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ItemInfo
ItemInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ItemInfo;
