import React from 'react';
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";

//icon
import HeightIcon from '@mui/icons-material/Height';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Stack from '@mui/material/Stack';

// List regarding items 
import Box from '@mui/material/Box';
import { ListItem, ListSubheader } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
//import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';

import CancelIcon from '@mui/icons-material/Cancel';

import MDDatePicker from "components/MDDatePicker";



// function PatientResultAwaited() {

const PatientResultawaited = () => {
  // const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);


  const [color] = useState("#344767");

  const [item, setItem] = useState({});

  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);


  const [mmuvalue, setMmuvalue] = useState(null);
  const [openline, setOpenLine] = React.useState(false);


  const [getResponse, setResponse] = useState([]);
  const [mmucode, setMmucode] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);
  const [givenQty, setGivenQty] = useState("");
  const [selectfrequency, setSelectfrequency] = useState(null);
  const [frequencyList, setFrequencyList] = useState("");
  const [medList, setmedList] = useState("");
  const [selectmed, setSelectmed] = useState(null);
  const [diseaseList, setDiseaseList] = useState("");
  const [uom, setUom] = useState("");
  const [dose, setDose] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState();
  const [remark, setRemark] = useState("");
  const [aggregiatepatient, setAggregiatepatient] = useState("");
  const [finalLabtest, setFinalLabtest] = useState([]);
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")));
  const [createdBy, setCreatedBy] = useState(loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname);
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const dataObj = {
    columns: [
      { Header: "Medicine", accessor: "medicinename" },
      { Header: "frequency", accessor: "frequency" },
      { Header: "dose", accessor: "dose" },
      { Header: "duration", accessor: "duration" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);
 
 
  
  

  const completePrescrptionObject = {
    additionalnotes: remark,
    age: aggregiatepatient?.patient?.age,

    
    diagnosistext: remark,

    createdby: createdBy,

    diagnosiscategory: "",


    doctor: loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname,

    history: "",
    id: null,
    labtestlist: finalLabtest,
    medicinelist: tableData.rows,
    mmucode: mmucode,
    name: aggregiatepatient?.patient?.name,
    nextfollowupdate: new Date(startDate),
    parentpresid: "",
    patientid: aggregiatepatient?.patient?.patientid,
    prestype: "",
    reasontoreferal: "",
    recstatus: "PRESCRIBED",
    refereddoctor: "",
    status: "ACTIVE",
    syncstatus: "SYNCED",

    visitData: aggregiatepatient?.visit,
    visitid: aggregiatepatient?.visit?.visitid,

  }
  const removeItem = function (uniqueKey) {

    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.medicineid === uniqueKey);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    // values.EquipmentList = tableDatasMed.rows;
  };

  const addData = (doseData) => {
    console.log("167", doseData);
    const dose = {
      countperday: doseData.countperday,
      createdby: createdBy,
      documentofproof: "",
      dosage: doseData.name,
      dose: doseData.dose,
      duration: doseData.duration,
      form: "",
      frequency: doseData.frequency,
      id: null,
      medicineid: doseData.id,
      medicinename: doseData.name,
      mmucode: mmucode,

      quantity: "",
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "NOT_SYNCED",
      tempid: "",
      uom: doseData.uom,
      //visitid: aggregiatepatient?.visit?.visitid
    }


    console.log("182", dose);
    // console.log(listOfMedicine);
    dose.action = (
      <span>

        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",

            }}
            onClick={() => removeItem(dose.medicineid)}
          />
        </Link>

      </span>
    );



    let filter = tableData.rows.filter(
      (a) => a.medicineid == dose.medicineid
    );

    if (filter.length < 1) {

      tableData.rows.push(dose);
      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      // values.listOfMedicine = tableData.rows;
    }

  };
  const defaultPropsdisease = {
    options: !diseaseList ? [{ name: "Loading...", id: 0 }] : diseaseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsmediciene = {
    options: !medList ? [{ name: "Loading...", id: 0 }] : medList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsfrequency = {
    options: !frequencyList ? [{ name: "Loading...", id: 0 }] : frequencyList,
    getOptionLabel: (option) => option.description,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };
  const handleCloseline = () => {
    console.log("dsfsdfs");
    setOpenLine(false);
  };

 
  
  async function createPrescription(object){
    await (await axios.post(`aggregation-service/api/Aggregation/v1/createPrescription`, object))
    .then((response)=>{})
    .catch((e)=>{console.log(e);})
}
const handleSubmitPrescription = () => {
    setFinalLabtest([])
    console.log("225",selectedTestlist);
    for(let i=0; i<selectedTestlist.length; i++){
        const labTestObj=  {
            createdby: createdBy,
            detailedresult: null,
            id: null,
            investigationlist:selectedTestlist[i].investigationlist,
            
            mmucode: mmucode,
            recstatus: "OPEN",
            status: "ACTIVE",
            syncstatus: "NOT_SYNC",
            testid: selectedTestlist[i].id,
            testname: selectedTestlist[i].testname,
            testresult: "",
            visitid: aggregiatepatient?.visit?.visitid
          }
          finalLabtest.push(labTestObj);
    }
    console.log("244",finalLabtest);
    for(let i=0; i<completePrescrptionObject.medicinelist.length; i++){
        delete completePrescrptionObject.medicinelist[i].action;
    }
    completePrescrptionObject.visitData.recstatus = "PRESCRIBED";
    console.log("complet Pres OBJ", completePrescrptionObject);
    createPrescription(completePrescrptionObject);
}


  async function getDiseaseData() {
    await axios
      .get(
        `disease-service/api/Disease/v1/get`
      )
      .then((response) => {
        setDiseaseList(response.data.data.dtoList);

        console.log("168", response.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
    await axios
      .get(
        `item-master-service/api/itemMaster/v1/get?type=MEDICINE`
      )
      .then((response) => {
        setmedList(response.data.data.dtoList);
        console.log("44", response.data.data.dtoList);
        // setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });



    await axios
      .get(
        `frequency-master-service/api/frequencyMaster/v1/get`
      )
      .then((response) => {
        setFrequencyList(response.data.data.dtoList)

      })
      .catch((e) => {
        console.log("error", e);
      });


  }



  async function getpatientResultawaited(mmucode) {
    //setLoading(true);

    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/get?mmucode=${mmucode}&recstatus=RESULT_AWAITED`
      )
      .then((response) => {
        response.data.data.visitAggregationDTOList.map((item) => {
          item.dataObj = {
            columns: [

              { Header: "Vitals", accessor: "name" },
              { Header: "uom", accessor: "uom" },

              { Header: "Value", accessor: "value" },
            ],
            rows: item.vitallist,
          };

        });
        setResponse(response.data.data.visitAggregationDTOList);
        console.log("====================================");
        console.log(getResponse);
        console.log(response.data.data.visitAggregationDTOList);
        console.log("====================================");
        getDiseaseData();
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }


  //---------------------------- Inside Add medicine --> medicine input button PopUp ------------
  const [addLine, setaddLine] = React.useState(false);

  const handleCloseAddLine = () => {
    console.log("dsfsdfs");
    setaddLine(false);
  };
  useEffect(() => {
    getpatientResultawaited(mmucode);

  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Patient Result Awaited
                  </MDTypography>
                </span>



              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Name :
                          </text>
                          {" " + values.patient?.name}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Age :
                          </text>
                          {" " + values.patient?.age}
                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>


                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>



                    <MDButton
                      variant="gradient"
                      color="info"
                      size='small'
                      type="submit"
                      style={{ marginLeft: "342px" }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Add Medicine
                    </MDButton>



                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>

      {/* Add Medicine popup================================================= */}
      <Dialog open={open} onClose={handleClose}>
        <CancelIcon onClick={() => {
          setOpen(false);
        }} />
        <DialogContent style={{ width: 550, height: 530 }}>

          <Grid container spacing={3} style={{ marginTop: ".1px" }}>
            <Grid item xs={6} sm={6}>
   
                   <Autocomplete
                multiple
                id="tags-standard"
              
                {...defaultPropsdisease}
                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Disease"
                  
                  />
                )}
              />

            </Grid>


            <Grid item xs={6} sm={6} >
              <MDInput
                style={{ width: "100%" }}
                label="Remark"
                size="medium"
                multiline
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid>
            <h4 style={{ textAlign: "center", marginTop: "20px" }}> Medicine </h4>
          </Grid>



          {/* Change in this one  */}

          <Grid container spacing={3} style={{ marginTop: ".1px" }}>

            {/* ===== 1st box ===== */}
            <Grid item xs={6} sm={6}>
              <Autocomplete
                {...defaultPropsmediciene}
                value={selectmed}
                onChange={(event, newValue) => {
                  // handleChange(event);


                  console.log("711", newValue)

                  setSelectmed(newValue)
                  setUom(newValue.uom)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}

                    label="Medicine"

                  />
                )}
              />
            </Grid>

            {/* ====== 2nd Box ======= */}
            <Grid item xs={6} sm={2} >
              <MDInput
                style={{ width: "100%" }}
                value={uom}
                disabled="true"
                label="UOM"
                size="large"
              />

            </Grid>
            <Grid item xs={6} sm={4}>
              <Autocomplete

                {...defaultPropsfrequency}
                value={selectfrequency}
                onChange={(event, newValue) => {
                  // handleChange(event);


                  console.log("756", newValue)

                  setSelectfrequency(newValue)
                  selectmed.frequency = newValue.description;
                  selectmed.countperday = newValue.countperday;

                }}
                renderInput={(params) => (
                  <TextField
                    {...params}

                    label="Frequency"
                    placeholder="Select Frequency"
                  />
                )}
              />
            </Grid>
          </Grid>


          <Grid container spacing={3} style={{ marginTop: ".1px" }}>

            {/* First Box */}
            <Grid item xs={6} sm={3}>

              <MDInput
                disabled="true"
                style={{ width: "100%" }}
                label="FORM"
                size="large"
              />
            </Grid>


            <Grid item xs={6} sm={3}>
              <MDInput
                name="dose"
                type="number"
                label="Dose"
                onChange={(e) => {
                  setDose(e.target.value)
                  selectmed.dose = e.target.value
                }
                }



              />
            </Grid>


            <Grid item xs={6} sm={3}>
              <MDInput
                name="Duration"
                type="number"
                label="Duration days"
                onChange={(e) => {
                  setDuration(e.target.value)
                  selectmed.duration = e.target.value
                }}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={() => addData(selectmed)}
              >
                Add line
              </MDButton>
            </Grid>
          </Grid>




          {tableData && <Grid container spacing={3} style={{ marginTop: ".1px" }}>
            <Grid item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "25px",
                marginLeft: "25px",
              }}
            >
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={tableData}
              />
            </Grid>
          </Grid>
          }

          <Grid item xs={12} sm={6} mt={5}>

            <MDDatePicker selected={startDate}
              onChange={setStartDate}
              input={{ placeholder: "  Select Follow-Up Date" }} />

          </Grid>



        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="info"
            size='small'
            type="submit"
          onClick={() => {
            handleSubmitPrescription();
            setOpen(false);
          }}
          >
            Submit
          </MDButton>

        </DialogActions>
      </Dialog>
      <Dialog open={openline} onClose={handleCloseline}>

        <DialogContent style={{ width: 350, height: 365 }}>
          <Grid container spacing={3}>


            <Grid item xs={12} sm={9} style={{ marginTop: "15px" }}>

              <Autocomplete
                {...defaultPropsmediciene}
                value={selectmed}
                onChange={(event, newValue) => {
                  // handleChange(event);


                  console.log("711", newValue)

                  setSelectmed(newValue)
                  setUom(newValue.uom)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}

                    label="Medicine "
                    placeholder="Select Medicine"
                  />
                )}
              />
            </Grid>
          </Grid>






          <Grid item xs={12} sm={9} style={{ marginTop: "15px" }}>
            <MDInput
              style={{ width: "100%" }}
              value={uom}
              disabled="true"
              label="UOM"
              size="large"
            />

          </Grid>



          <Grid container spacing={3}>
            <Grid item xs={12} sm={9} style={{ marginTop: "8px" }}>

              <Autocomplete
                disablePortal
                id="combo-box-demo"

                {...defaultPropsfrequency}
                value={selectfrequency}
                onChange={(event, newValue) => {

                  setSelectfrequency(newValue);
                  selectmed.frequency = newValue.description;
                  selectmed.countperday = newValue.countperday;
                  console.log(newValue);
                }}

                renderInput={(params) => (
                  <TextField {...params} label="frequency" />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9} style={{ marginTop: "15px" }}>
            <MDInput
              style={{ width: "100%" }}
              // value={uom}
              disabled="true"
              label="form"
              size="large"
            />

          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>

              <h5 style={{ marginTop: "22px" }}>Dose</h5>
            </Grid>

            <Grid item xs={12} sm={7} >


              <MDInput
                type="text"
                label="Dose"



              />
            </Grid>

          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>

              <h5 style={{ marginTop: "22px" }}>Duration Days</h5>
            </Grid>
            <Grid item xs={12} sm={7} >
              <MDInput
                type="text"
                label="Duration Days"



              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseline}>Cancel</Button>
          <Button >Submit</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>

  );
}



export default PatientResultawaited;
