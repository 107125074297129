import * as React from 'react';
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Autocomplete } from "@mui/material";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";


//EDit button

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

function ItemList() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [itemtList, setItemList] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const [selectitem, setSelectItem] = useState('');
  const [uomlist, setUomlist] = useState('');
  const [selectuom, setSelectuom] = useState('');
  const [updateName, setUpdateName] = useState("");
  const [uom, setUom] = useState("");
  const [sapcode, setSapcode] = useState("");
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const defaultPropsuom = {
    options: !uomlist ? [{ name: "Loading...", id: 0 }] : uomlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  }
  const defaultProps = {
    options: !itemtList ? [{ name: "Loading...", id: 0, }] : itemtList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getItemtData() {
    await axios
      .get(`type-master-service/api/TypeMaster/v1/get?category=ITEM`)
      .then((response) => {
        setItemList(response.data?.data?.dtoList);
        getUomData(response.data?.data?.dtoList)

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getUomData(itemIdList) {
    await axios
      .get(`type-master-service/api/TypeMaster/v1/get?category=UOM`)
      .then((response) => {
        setUomlist(response.data?.data?.dtoList);
        getData(itemIdList, response.data?.data?.dtoList);


      })

      .catch((e) => {
        console.log("error", e);
      });
  }




  async function onUpdatedata(object, itemIdList, uomIdList) {

    for (let i = 0; i < itemIdList.length; i++) {

      if (itemIdList[i].name === object.type) {
        console.log("900", itemIdList[i])
        setSelectItem(itemIdList[i]);
      }
    }
    for (let i = 0; i < uomIdList.length; i++) {
      if (uomIdList[i].name === object.uom) {
        console.log("118", uomIdList[i])
        setSelectuom(uomIdList[i]);
      }
    }
    setItem(object)

    setUpdateName(object.name);
    setSapcode(object.mmucode);
    setOpen(true);
  
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };


  async function onDelete(item) {
    if (window.confirm("Are you sure that you want to delete that Item record"))
      await axios
        .put(
          "item-master-service/api/itemMaster/v1/delete",
          item
        )
        .then((response) => {
          console.log(response);
          getItemtData();
        })
        .catch((error) => {
          console.log(error);
        });
  }
  async function updateData() {

    item.name = updateName;
    item.mmucode = sapcode;
    
    item.modifiedby = JSON.parse(localStorage.getItem('mmu-auth')).firstname + " " + JSON.parse(localStorage.getItem('mmu-auth')).lastname;
    delete item.action
    await axios
      .put(
        "item-master-service/api/itemMaster/v1/update",
        item
      )
      .then((response) => {

        setOpen(false);
        getItemtData();
      })
      .catch((error) => {
        console.log(error);
      });

  }




  function getData(itemIdList, uomIdList) {
    console.log("182", uomIdList);
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "Sap Code", accessor: "mmucode" },
        { Header: "Item Type", accessor: "type" },
        { Header: "Uom", accessor: "uom" },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        `item-master-service/api/itemMaster/v1/get`
      )
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item, itemIdList, uomIdList);
                  }}
                />
              </Link>

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>

            </span>
          );
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getItemtData();

  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Item Table
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/users/Create-item"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Update Item</DialogTitle>
        <DialogContent style={{ width: 550, height: 230 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label=" Name"
              variant="standard"
              fullWidth
              value={updateName}
              onChange={(e) => setUpdateName(e.target.value)}

            />
          </MDBox>
          <MDBox mb={2}>


            <Autocomplete
              {...defaultProps}
              value={selectitem}
              onChange={(event, newValue) => {
                // handleChange(event);
                

                setSelectItem(newValue);
                 item.type = newValue.name;
                       }}
              renderInput={(params) => (
                <MDInput
                  {...params}

                  variant="standard"
                  label="Item Type"
                />
              )}
            />
          </MDBox>
          <MDBox mb={2}>

           
              <MDInput
                type="text"
                label="Sapcode"
                variant="standard"
                fullWidth
                value={sapcode}
                onChange={(e) => setSapcode(e.target.value)}

              />

            </MDBox>
          
          <MDBox mb={2}>
          <Autocomplete
            {...defaultPropsuom}
            value={selectuom}
            onChange={(event, newValue) => {
              // handleChange(event);
           

              setSelectuom(newValue);
              item.uom = newValue.name;
             

            }}
            renderInput={(params) => (
              <MDInput
                {...params}

                variant="standard"
                label="UOM"
              />
            )}
          />
            </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} >Update</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default ItemList