// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
import MDDatePicker from "components/MDDatePicker";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link, useNavigate } from "react-router-dom";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Accordians from "./Accordians";
import { Autocomplete, Grid, TextField } from "@mui/material";
import input from "assets/theme/components/form/input";

function RequisitionFulFillment() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [getResponse, setResponse] = useState([]);
  const [approvedQty, setApprovedQty] = useState(0);
  const [givenQty, setGivenQty] = useState("");
  const [show, setShow] = useState(false);

  // const [givenQtyValue, setGivenQtyValue] = useState("0");
  const [warevalue, setWarevalue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [wareHouseList, setWarehouseList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).childwarehouselist
  );
  const [mmuCodeList, setmmuCodeList] = useState(null);
  // const [tableStatus, setTableStatus] = useState();
  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
    getOptionLabel: (option) => option.name + " (" + option.vehicle?.name + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  function settingGivenQty(responseList, item, lineItem, value) {
    console.log("setting", item);
    console.log("setting v", value);
    console.log("setting line", lineItem);

    for (let i = 0; i < item.medrequisitiongendtoList.length; i++) {
      if (item.medrequisitiongendtoList[i].id === lineItem.id) {
        item.medrequisitiongendtoList[i].quantityrcvd = value;
        console.log("finalLineItemList", item.medrequisitiongendtoList);
      }
    }

    for (let j = 0; j < responseList.length; j++) {
      if (
        responseList[j].medrequisitiongentxndto.id ===
        item.medrequisitiongentxndto.id
      ) {
        responseList[j] = item;
      }
    }

    console.log("res", responseList);
    setResponse(responseList);
  }
  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }
  async function getDataBywharehouse(wharehouse) {
    setLoading(true);

    await axios
      .get(
        `med-requisition-gen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?recStatus=APPROVED&owhid=${wharehouse}`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "warehouse", accessor: "whid" },
              { Header: " type", accessor: "itemtype" },

              { Header: "Requested Qty", accessor: "quantityrqst" },
              { Header: "batch no", accessor: "batch" },
              { Header: "manufacturing date", accessor: "manufaturingDate" },
              { Header: "expiring date", accessor: "expiringDate" },
              { Header: "Given Qty ", accessor: "input" },
            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            i.itembatch = {
              batchno: "",
              manufacturingdate: "",
              expairydate: "",
              itemcode: i.sapitemcode,
              itemid: i.name,
              quantity: "",
            };
            i.input = (
              <MDInput
                type="number"
                variant="outlined"
                size="small"
                name={i.id}
                defaultValue={0}
                onChange={(e) => {
                  i.quantityrcvd = e.target.value;
                  i.itembatch.quantity = e.target.value;
                }}
                style={{ width: "70px" }}
              />
            );
            i.batch = (
              <MDInput
                type="text"
                variant="outlined"
                size="small"
                name={i.id}
                // defaultValue={0}
                onChange={(e) => (i.itembatch.batchno = e.target.value)}
                style={{ width: "70px" }}
              />
            );
            i.manufaturingDate = (
              <MDDatePicker
                input={{ placeholder: "dd-mm-yyy" }}
                onChange={([date]) => {
                  // console.log(date);
                   
                 // const y = date;
                 //var formatedDate= date.toDateString()
                  // var formatedDate = moment(date).format(); 
                //  formatedDate.setHours(formatedDate.getHours() + 5);
                //  formatedDate.setMinutes(formatedDate.getMinutes() + 30);
                
                 // console.log("178", formatedDate);
                 
                  i.itembatch.manufacturingdate = date;
                  console.log("181",date.toDateString());
                }}
              />
            );
            i.expiringDate = (
              <MDDatePicker
                input={{ placeholder: "Select a date" }}
                onChange={([date]) => {
                  console.log(date);
                  i.itembatch.expairydate = date;
                  console.log("192",date.toDateString());
                }}
              />
            );

            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        console.log("====================================");
        console.log(getResponse);
        console.log(response.data.data.medRequisitionAggregationList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDataBymmu(mmucode) {
    setLoading(true);

    await axios
      .get(
        `med-requisition-gen-service/api/MedRequisitionGen/v1/getRequisitionAggregation?recStatus=APPROVED&mmucode=${mmucode}`
      )
      .then((response) => {
        response.data.data.medRequisitionAggregationList.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "mmu", accessor: "mmucode" },
              { Header: " type", accessor: "itemtype" },

              { Header: "Requested Qty", accessor: "quantityrqst" },
              { Header: "batch no", accessor: "batch" },
              { Header: "manufacturing date", accessor: "manufaturingDate" },
              { Header: "expiring date", accessor: "expiringDate" },
              { Header: "Given Qty ", accessor: "input" },
            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            i.input = (
              <MDInput
                type="number"
                variant="outlined"
                size="small"
                name={i.id}
                defaultValue={0}
                onChange={(e) => {
                  i.quantityrcvd = e.target.value;
                  // settingGivenQty(
                  //   response.data.data.medRequisitionAggregationList,
                  //   item,
                  //   i,
                  //   e.target.value
                  // );
                }}
                style={{ width: "70px" }}
              />
            );
            i.batch = (
              <MDInput
                type="number"
                variant="outlined"
                size="small"
                name={i.id}
                defaultValue={0}
                // onChange={
                //   (e) => (i.quantityrcvd = e.target.value)

                // }
                style={{ width: "70px" }}
              />
            );
            i.manufaturingDate = (
              <MDDatePicker input={{ placeholder: "Select a date" }} />
            );
            i.expiringDate = (
              <MDDatePicker input={{ placeholder: "Select a date" }} />
            );

            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        console.log("====================================");
        console.log(getResponse);
        console.log(response.data.data.medRequisitionAggregationList);
        console.log("====================================");

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getMmuDataByWarehouse(whcode) {
    setLoading(true);

    await axios
      .get(
        `mmu-creation-service/api/MMUCreation/v1/get?warehouse.whmmucode=${whcode}`
      )
      .then((response) => {
        setmmuCodeList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function handleSubmit(updateObject, resList) {
    console.log("updateObj", updateObject);
    for (let i = 0; i < updateObject.medrequisitiongendtoList.length; i++) {
      delete updateObject.medrequisitiongendtoList[i].input;
      if (
        updateObject.medrequisitiongendtoList[i].quantityrcvd === "" ||
        updateObject.medrequisitiongendtoList[i].quantityrcvd === null ||
        typeof updateObject.medrequisitiongendtoList[i].quantityrcvd ===
          "undefined"
      ) {
        updateObject.medrequisitiongendtoList[i].quantityrcvd = "0";
      }
      updateObject.medrequisitiongendtoList[i].recstatus = "RECIEVED";
    }
    updateObject.medrequisitiongentxndto.recstatus = "RECIEVED";

    console.log("finalUpObj", updateObject);
    // update-line-items=====
    axios
      .put(
        "med-requisition-gen-service/api/MedRequisitionGen/v1/update",
        updateObject.medrequisitiongendtoList
      )

      .then((response) => {
        console.log("107", response);

        // for (let i = 0; i < updateObject.medrequisitiongendtoList.length; i++) {
        //   updateObject.medrequisitiongendtoList[i].quantityrcvd = "";
        // }
        updateMedTxn(updateObject.medrequisitiongentxndto, resList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const updateMedTxn = async (object, resList) => {
    await axios
      .put(
        "med-requisition-gen-service/api/MedRequisitionGen/v1/updateRequisition",
        object
      )
      .then((response) => {
        console.log("107", response);
        var medReqTxnDTO = response.data.data.dto;
        console.log("265", resList);
        // var finalResponse = [];

        // for (let i = 0; i < resList.length; i++) {
        //   if (
        //     medReqTxnDTO.mrnumber !==
        //     resList[i].medrequisitiongentxndto.mrnumber
        //   ) {
        //     // delete resList[i];
        //     console.log("277", resList[i]);
        //     finalResponse.push(resList[i]);
        //   }
        // }
        // setResponse(finalResponse);
        // console.log("281", getResponse);
        if (
          medReqTxnDTO?.mmucode !== "" &&
          typeof medReqTxnDTO?.mmucode !== "undefined" &&
          medReqTxnDTO?.mmucode !== null
        ) {
          console.log("gettin by mmu");
          getDataBymmu(medReqTxnDTO?.mmucode);
        } else {
          console.log("gettin by ware");
          getDataBywharehouse(medReqTxnDTO?.whid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // navigate("pages/stock/requsition-list");
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Requisition Fulfillment
                  </MDTypography>
                </span>
                <Grid container spacing={3} my={1}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsWharehouse}
                      value={warevalue}
                      onChange={(event, newValue) => {
                        getMmuDataByWarehouse(newValue.whmmucode);
                        getDataBywharehouse(newValue.whmmucode);
                        setWarevalue(newValue);
                        setMmuvalue(null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select WareHouse" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280, marginLeft: "24px" }}
                      {...defaultPropsmmuCode}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getDataBymmu(newValue?.code);
                        setMmuvalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            <div>
              {!loading &&
                getResponse.map((values) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <div style={{ fontSize: "15px", color: "#7b809a" }}>
                          <div>
                            <text style={{ fontWeight: "bold" }}>
                              Requested Id :
                            </text>
                            {" " + values.medrequisitiongentxndto.mrnumber}
                          </div>
                          <div>
                            <text style={{ fontWeight: "bold" }}>
                              Submitted By :
                            </text>
                            {" " + values.medrequisitiongentxndto.createdby}
                          </div>
                          <div>
                            <text style={{ fontWeight: "bold" }}>Status :</text>
                            {" " + values.medrequisitiongentxndto.recstatus}
                          </div>
                          <div>
                            <text style={{ fontWeight: "bold" }}>Date :</text>
                            {" " +
                              new Date(
                                values.medrequisitiongentxndto.createddate
                              ).toDateString()}
                          </div>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDButton
                        circular="true"
                        color="success"
                        size="small"
                        style={{ marginLeft: "940px" }}
                        variant="gradient"
                        onClick={() => handleSubmit(values, getResponse)}
                      >
                        Submit
                      </MDButton>
                      <Typography>
                        <text style={{ color: "#FF00FF" }}>
                          <DataTable
                            table={values.dataObj}
                            showTotalEntries={false}
                            entriesPerPage={false}
                          />
                        </text>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RequisitionFulFillment;

