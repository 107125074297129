

import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import FormField from "layouts/pages/item/new item/componets/FormField";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "axios";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdBadge, MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, FormControlLabel, Grid, Switch, TextField } from "@mui/material";

//icon
import HeightIcon from '@mui/icons-material/Height';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import MDBadge from "components/MDBadge";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
// import FormField from "../FormField";
const Searchpatient = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openvitals, setOpenvitals] = React.useState(false);
  const [openedit, setOpenedit] = React.useState(false);
  const [selectid, setSelectid] = useState(null);
  const [selectdoctor, setSelectdoctor] = useState(null);
  const [idcardList, setIdcardList] = useState("");
  const [doctorList, setDoctorList] = useState("");
  const [visittypeList, setvisittypelist] = useState("");
  const [visitcategoryList, setvisitcategoryList] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [docno, setDocno] = useState("");
  const [docType, setDocType] = useState("");
  const [selectvisittype, setSelectvisittype] = useState(null);
  const [selectvisitcategory, setSelectvisitcategory] = useState(null);
  const [openhistory, setOpenhistory] = React.useState(false);
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
  const [editobj, setEditobj] = useState({});

  const [mmuList, setMmuList] = useState(
    JSON.parse(localStorage.getItem("project-Detail")).mmulist);

  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")));

  const [mmuvalue, setMmuvalue] = useState(null);


  const [searched, setSearched] = useState(null);
  const [getResponse, setResponse] = useState([]);
  const [mmucode, setMmucode] = useState(null);
  const [givenQty, setGivenQty] = useState("");
  const [selectedPatient, setSelectedPatient] = useState();
  const [vitalsMasterList, setVitalsMasterList] = useState([]);
  const [getApiResponse, setApiResponse] = useState([]);
  const [idList, setidList] = useState("");
  const [patientId, setPatientId] = useState();

  const [mmucodeLocal, setMmucodeLocal] = useState(
    JSON.parse(localStorage.getItem("association"))[0].mmucode);

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const visitObj = {
    patientid: selectedPatient?.patientid,
    patienttempid: selectedPatient?.patienttempid,
    firstscreening: "",
    doctorid: selectdoctor?.id.toString(),
    mmucode: mmucode,
    visittype: selectvisittype?.name,
    visitcategory: selectvisitcategory?.name,
    createdby: loginUser.lastname ? loginUser.firstname + " " + loginUser.lastname : loginUser.firstname,
    recstatus: "OPEN",
    syncstatus: "NOT_SYNCED",
    status: "ACTIVE"
  }
  const defaultPropsprojectMMU = {
    options: !mmuList ? [{ name: "Loading...", id: 0 }] : mmuList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  // assign doctor popup drop down :- for select doctor=======================
  const defaultPropsDoctor = {
    options: !doctorList
      ? [
        { fullname: "Loading....", profileid: "" },

      ]
      : doctorList,
    getOptionLabel: (option) => option.fullname,
    isOptionEqualToValue: (option, value) => option.profileid === value.profileid,
  };
  const defaultPropsvisittype = {
    options: !visittypeList
      ? [
        { name: "New", code: "X" },
        { name: "FollowUp", code: "y" },

      ]
      : visitcategoryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const defaultPropsVisitcategory = {
    options: !visitcategoryList
      ? [
        { name: "MMU Visit", code: "X" },
        { name: "Home Visit", code: "y" },

      ]
      : visittypeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  // editpopup drop down :- for select id card=======================
  const defaultPropsId = {
    options: !idList ? [{ name: "Loading...", id: 0 }] : idList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getIdCardData() {
    await axios
      .get(`type-master-service/api/TypeMaster/v1/get?category=compdoc`)
      .then((response) => {
        setidList(response.data?.data?.dtoList);
        // getUomData(response.data?.data?.dtoList)

      })
      .catch((e) => {
        console.log("error", e);
      });
  }





  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  const handleSubmit = () => {
    console.log("visit", visitObj);
    createVisit(visitObj)
    selectedPatient.recstatus = "ASSIGNED"
    updatePatient(selectedPatient);
    setOpen(false)
  }
  // vital handle close====================

  const handleClosevitals = () => {
    console.log("dsfsdfs");
    setOpenvitals(false);
  };

  // edit handle close====================

  const handleCloseedit = () => {
    console.log("dsfsdfs");
    setOpenedit(false);
  };
  const handleClosehistory = () => {
    console.log("dsfsdfs");
    setOpenhistory(false);
  };
  async function getpatient(mmucode) {
    // setLoading(true);

    await axios
      .get(
        `patient-service/api/patient/v1/get?mmucode=${mmucode}`
      )
      .then((response) => {
        setResponse(response.data.data.dtoList);
        setApiResponse(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }


  async function getMMUAssociation(profileId) {
    setLoading(true);

    await axios
      .get(

        `mmu-association-service/api/MMUAssociation/v1/getAllByProfileId/${profileId}`
      )
      .then((response) => {
        console.log(response.data.data.dtoList);
        console.log(response.data.data.dtoList[0].rolecode);
        var docArr = []
        response.data.data.dtoList.map((item) => {
          console.log(item.rolecode)
          if (item.rolecode.includes("DO")) {
            docArr.push(item);
            console.log("198", item);
          }
        })
        if (response.data.data.dtoList.length > 0) {
          setMmucode(response.data.data.dtoList[0].mmucode);
          getpatient(response.data.data.dtoList[0].mmucode)
        }
        setDoctorList(docArr);
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  //view history data ============================
  const [historyDataList, setHistoryDataList] = useState()
  async function historyData(patientObject) {
    var mmucode = JSON.parse(localStorage.getItem("association"))[0].mmucode;
    console.log("242", selectedPatient);
    setPatientId(patientObject.patientid)
    // var patientId = patientObject.patientid;
    setLoading(true);

    await axios
      .get(

        `aggregation-service/api/Aggregation/v1/get?mmucode=${mmucode}&patientid=${patientObject.patientid}&recstatus=PRESCRIBED`
      )
      .then((response) => {
        console.log("247", response);
        setHistoryDataList(response.data.data.visitAggregationDTOList);
        // var docArr = []
        // response.data.data.dtoList.map((item) => {
        //   if (item.rolecode == "DO75") {
        //     docArr.push(item);
        //     console.log("198", item);
        //   }
        // })
        // if (response.data.data.dtoList.length > 0) {
        //   setMmucode(response.data.data.dtoList[0].mmucode);
        //   getpatient(response.data.data.dtoList[0].mmucode)
        // }
        // setDoctorList(docArr);
        // // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function createVisit(object) {
    // setLoading(true);

    await axios
      .post(

        `visit-service/api/visit/v1/create`, object
      )
      .then((response) => {
        console.log(response.data.data.dtoList);

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const [finalPatientObject, setFinalPatientObject] = useState()
  async function editPatient(object, itemIdList) {
    console.log("295", object);
    console.log("310", itemIdList)
    for (let i = 0; i < itemIdList.length; i++) {

      if (itemIdList[i].name == object.documenttype) {
        console.log("313", itemIdList[i])
        setSelectid(itemIdList[i]);
      }
    }

    setEditobj(object)

    setMobileno(object.mobileno);
    setDocno(object.documentno)
    // setSelectid({ name: object.documenttype})
  }
  async function editPatientupdate() {
    editobj.mobileno = mobileno;
    editobj.documentno = docno;
    editobj.documenttype = docType;
    item.modifiedby = JSON.parse(localStorage.getItem('mmu-auth')).firstname + " "
      + JSON.parse(localStorage.getItem('mmu-auth')).lastname;
    delete editobj.action
    await axios
      .put(
        'patient-service/api/patient/v1/update',
        editobj
      )
      .then((response) => {
        console.log("316", response);
        setOpenedit(false);
        getMMUAssociation(loginUser.profileid);
        getIdCardData();
        getVitalMaster()
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function updatePatient(object) {
    // setLoading(true);

    await axios
      .put(

        `patient-service/api/patient/v1/update`, object
      )
      .then((response) => {
        console.log(response.data.data.dtoList);

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getVitalMaster() {
    // setLoading(true);

    await axios
      .get(

        `vitals-master-service/api/VitalsMaster/v1/get`
      )
      .then((response) => {
        console.log(response.data.data.dtoList);
        setVitalsMasterList(response.data.data.dtoList)
        console.log("281", response.data.data.dtoList);
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  //search button
  const requestSearch = (keyData) => {
    console.log("392", keyData)
    //setSearched(key);
    const searchdata = getResponse.filter((key) => {
      if (key.name.toLowerCase().includes(keyData.toLowerCase()) || key.age.includes(keyData) || key.documenttype.toLowerCase().includes(keyData.toLowerCase()) || key.mobileno.includes(keyData) || key.village.toLowerCase().includes(keyData.toLowerCase())) {
        return key;
      }

    });
    if (keyData === null || keyData.length === 0) {
      setResponse(getApiResponse);
    }
    else
      setResponse(searchdata);
  };



  async function createVitals(objectList) {
    // setLoading(true);

    await axios
      .post(

        `vitals-service/api/Vitals/v1/createList`,
        objectList
      )
      .then((response) => {
        console.log(response.data.data.dtoList);
        getMMUAssociation(loginUser.profileid);
        getVitalMaster()
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        getMMUAssociation(loginUser.profileid);
        getVitalMaster()
      });
  }
  function handleCreateVitals() {
    console.log("291", vitalsMasterList);
    for (let i = 0; i < vitalsMasterList.length; i++) {
      vitalsMasterList[i].id = null;
      vitalsMasterList[i].recstatus = "OPEN";
    }
    createVitals(vitalsMasterList);
    setOpenvitals(false)
  }
  useEffect(() => {
    getMMUAssociation(loginUser.profileid);
    getIdCardData();
    getVitalMaster()
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
             
              
                  <Grid container spacing={3} mt={2}>
                    <Grid item xs={12} sm={6}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Search Patient
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDBox width="12rem" ml="auto">
                        <MDInput
                          placeholder="Search..."
                          value={searched}

                          onChange={(e) => {
                            requestSearch(e.target.value)
                            setSearched(e.target.value)
                          }}


                        />
                      </MDBox>
                    </Grid>
                 
              
                </Grid>
                <Grid container spacing={3} my={1}>
                  <Grid item xs={12} sm={7}>

                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      style={{ marginLeft: "20px" }}
                      sx={{ width: 300 }}
                      {...defaultPropsprojectMMU}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getpatient(newValue.code)
                        setMmuvalue(newValue);
                        console.log(newValue);
                      }}

                      renderInput={(params) => (
                        <TextField {...params} label="Select-mmu" />
                      )}
                    /> */}
                  </Grid>
                </Grid>
              
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Name :
                          </text>
                          {" " + values.name}
                          {values.recstatus == "ASSIGNED" && (
                            <MDBadge
                              badgeContent={values.recstatus}
                              size="md"
                              style={{ marginLeft: "12px" }}
                              color="primary"
                              gradient
                            />
                          )}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Age :
                          </text>
                          {" " + values.age}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Patient ID :
                          </text>
                          {" " + values.patientid}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Mobile No :
                          </text>
                          {" " + values.mobileno}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Village :
                          </text>
                          {" " + values.village}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Document
                            Type :
                          </text>
                          {" " + values.documenttype}

                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Doc. No. :
                          </text>
                          {" " + values.documentno}

                        </div>
                        {/* <div>
                          <text style={{ fontWeight: "bold" }}>
                            SUBMITED by :
                          </text>
                          {" " + values.medrequisitiongentxndto.createdby}
                        </div> */}


                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size='small'
                          type="submit"
                          onClick={() => {
                            setSelectedPatient(values);
                            setOpen(true);
                          }}
                        >
                          Assign
                          Doctor
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MDButton
                          style={{ marginLeft: "-47px" }}
                          variant="gradient"
                          color="info"
                          size='small'
                          type="submit"
                          onClick={() => {
                            setOpenvitals(true);
                          }}
                        >
                          create vitals
                        </MDButton>
                        {/* popup view history============================= */}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size='small'
                          type="submit"
                          onClick={() => {
                            setSelectedPatient(values);
                            historyData(values);
                            setOpenhistory(true);

                          }}
                        >
                          View History
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MDButton
                          style={{ marginLeft: "-47px" }}
                          variant="gradient"
                          color="info"
                          size='small'
                          type="submit"
                          onClick={() => {
                            setOpenedit(true);

                            editPatient(values, idList);


                          }}
                        >
                          Edit Patient
                        </MDButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>

      {/* assign Doctor popup================================================= */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle >Assign Doctor</DialogTitle>
        <DialogContent style={{ width: 550, height: 450 }}>
          <Grid container spacing={3} style={{ marginTop: ".1px" }}>
            <Grid item xs={12} sm={4} style={{ marginTop: "7px" }}>
              <h3>
                Available Dr :
              </h3>


            </Grid>
            <Grid item xs={12} sm={5} >
              <Autocomplete
                {...defaultPropsDoctor}
                value={selectdoctor}
                onChange={(event, newValue) => {

                  setSelectdoctor(newValue);

                }}
                renderInput={(params) => (
                  <TextField style={{ marginLeft: "-28px" }}
                    {...params}


                    label="Doctor User"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: ".1px" }}>
            <Grid item xs={12} sm={3} style={{ marginTop: "7px" }}>
              <h3>
                Visit Type :
              </h3>


            </Grid>
            <Grid item xs={12} sm={5} >
              <Autocomplete
                {...defaultPropsvisittype}
                value={selectvisittype}
                onChange={(event, newValue) => {
                  setSelectvisittype(newValue);

                }}
                renderInput={(params) => (
                  <TextField
                    {...params}


                    label="New"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: ".1px" }}>
            <Grid item xs={12} sm={3} style={{ marginTop: "7px" }}>
              <h3>
                visit category :
              </h3>


            </Grid>
            <Grid item xs={12} sm={5} >
              <Autocomplete
                {...defaultPropsVisitcategory}
                value={selectvisitcategory}
                onChange={(event, newValue) => {
                  setSelectvisitcategory(newValue);

                }}
                renderInput={(params) => (
                  <TextField
                    {...params}


                    label="MMU visit"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: ".2px" }}>
            <Grid item xs={12} sm={7} style={{ marginTop: "7px", textAlign: "centre" }}>
              <h3>
                Additional notes<CameraAltIcon fontSize="small"></CameraAltIcon> :
              </h3>
            </Grid>



          </Grid>
          <Grid container spacing={3} style={{ marginTop: ".2px" }}>
            <Grid item sm={7} style={{ marginRight: "68px" }}>
              <MDInput
                style={{ width: "100%" }}
                // value={visitObj.firstscreening}
                onChange={(e) => { visitObj.firstscreening = e.target.value }}
                label="Aditional Information..."
                size="large"
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>


      {/* create vitlas popup================================================= */}
      <Dialog open={openvitals} onClose={handleClosevitals}>
        <DialogTitle style={{ textAlign: "centre" }}>create vital</DialogTitle>
        <DialogContent style={{ width: 550, height: 470 }}>
          <Grid container style={{ marginTop: "6px", textAlign: "center" }} spacing={0}>
            {vitalsMasterList.length > 0 &&
              vitalsMasterList.map((k) => (
                // <h1>{k.name} </h1>
                <Grid container spacing={3}>
                  <Grid item mt={2} xs={12} sm={4}>
                    {k.type == "VALUE" &&
                      <MDInput
                        type="number"
                        label={k.name}
                        name={k.name}
                        // value={k.value}
                        // onChange={()k.value}
                        onChange={(e) => { k.value = e.target.value }}
                      // placeholder={k.name}
                      />}
                    {k.type == "FLAG" &&
                      <FormControlLabel
                        name={k.name}
                        control={
                          <Switch
                            onClick={(e) => {
                              console.log("631", e.target.checked);
                              k.value = e.target.checked
                            }

                            }
                          // defaultChecked
                          />
                        }
                        label={k.name}
                      />}

                  </Grid>
                  <Grid item mt={2} xs={12} sm={4}>
                    {k.uom}
                  </Grid>
                </Grid>
              ))}
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosevitals}>Cancel</Button>
          <Button onClick={handleCreateVitals}>Create</Button>
        </DialogActions>
      </Dialog>


      {/* edit popup================================================= */}
      <Dialog open={openedit} onClose={handleCloseedit}>
        <DialogTitle style={{ textAlign: "centre" }} >Edit Patient</DialogTitle>
        <DialogContent style={{ width: 270, height: 190 }}>

          <MDInput
            style={{ marginTop: "8px" }}
            type="number"
            label="Mobile NO"

            fullWidth
            value={mobileno}
            onChange={(e) => setMobileno(e.target.value)}

          />


          <Autocomplete
            style={{ marginTop: "8px" }}
            {...defaultPropsId}
            value={selectid}
            // defaultValue={defaultIDType}
            onChange={(event, newValue) => {
              // handleChange(event);
              // item.type = newValue.name;

              setSelectid(newValue);
              // editobj.documenttype=newValue.name;
              setDocType(newValue.name)
              console.log("559", selectid)

            }}
            renderInput={(params) => (
              <TextField
                {...params}


                label="COMP.DO"
              />
            )}
          />




          <MDInput
            style={{ marginTop: "8px" }}
            type="text"
            label="DOC.NO"

            fullWidth
            value={docno}
            onChange={(e) => setDocno(e.target.value)}

          />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseedit}>Cancel</Button>
          <Button onClick={editPatientupdate} >Update</Button>
        </DialogActions>
      </Dialog>


      {/* view history popup ===============================================*/}
      <Dialog open={openhistory} onClose={handleClosehistory}>
        <DialogTitle style={{ textAlign: "centre" }}>View History</DialogTitle>
        {historyDataList && <DialogContent style={{ width: 550, height: 350 }}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",

            }}
          >


            <Grid item container spacing={3} >
              <Grid item xs={12} sm={1} >

                <ExpandCircleDownIcon fontSize="medium" style={{ marginTop: "18px" }}></ExpandCircleDownIcon>,
              </Grid>
              <Grid item xs={12} sm={11} >
                <MDTypography variant="h5" fontWeight="medium" style={{ marginTop: "18px" }}>
                  Patient ID : {patientId}
                </MDTypography>
              </Grid>

            </Grid>


          </Grid>

          <h5 style={{ margin: "1px" }}>#Visit ID: {historyDataList[0]?.visit?.visitid}</h5>
          <Grid
            item
            xs={12}
            sm={9}
            style={{
              border: "1px solid #727375",
              borderRadius: "9px",
              marginTop: "25px",
              marginLeft: "35px"

            }}
          >
            <h5 style={{ margin: "1px", textAlign: "center" }}>Prescription Result:</h5>



            {historyDataList[0]?.prescriptionlist.map((i) => (
              <Grid item xs={12} sm={11} style={{ margin: "5px" }} >
                <h5>
                  Prescription ID : {i.presid}
                </h5>
              </Grid>
            ))
            }



          </Grid>

          <Grid
            item
            xs={12}
            sm={9}
            style={{
              border: "1px solid #727375",
              borderRadius: "9px",
              marginTop: "25px",
              marginLeft: "35px"

            }}
          >
            <h5 style={{ margin: "1px", textAlign: "center" }}>Lab Test Result:</h5>



            {historyDataList[0]?.prescriptionlist.map((i) => (
              <Grid item xs={12} sm={11} style={{ margin: "5px" }} >
                <h5>
                  Prescription ID : {i.presid}
                </h5>
              </Grid>
            ))
            }



          </Grid>
        </DialogContent>}
        <DialogActions>
          <Button onClick={handleClosehistory}>Cancel</Button>
          <Button >Update</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
};




export default Searchpatient;














