// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/stock/new stock/componets/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function StockInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [MRNumberValue, setMRNumberValue] = React.useState(null);
  const [WHIDValue, setWHIDValue] = React.useState(null);
  const [flowTypeValue, setflowTypeValue] = React.useState(null);
  const [reqLineItemIdValue, setreqLineItemIdValue] = React.useState(null);
  const [mmuCodeValue, setmmuCodeValue] = React.useState(null);

  const [MRNNumberList, setMRNNumberList] = useState("");
  const [WHIDList, setWHIDList] = useState("");
  const [flowTypeList, setflowTypeList] = useState("");
  const [reqLineItemIdList, setreqLineItemIdList] = useState("");
  const [mmuCodeList, setmmuCodeList] = useState("");

  const { formField, values, errors, touched } = formData;
  const defaultPropsMRNNumber = {
    options: !MRNNumberList
      ? [{ MRNumber: "Loading...", id: 0 }]
      : MRNNumberList,
    getOptionLabel: (option) => option.MRNumber,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsWHID = {
    options: !WHIDList ? [{ name: "Loading...", id: 0 }] : WHIDList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsreqLineItemId = {
    options: !reqLineItemIdList
      ? [{ name: "Loading...", id: 0 }]
      : reqLineItemIdList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList ? [{ name: "Loading...", id: 0 }] : mmuCodeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const {
    MRNumber,
    WHID,
    flowType,
    reqLineItemId,
    mmuCode,
    PONumber,
    uom,
    quantity,
  } = formField;
  var {
    MRNumber: MRNumberV,
    WHID: WHIDV,
    flowType: flowTypeV,
    reqLineItemId: reqLineItemIdV,
    mmuCode: mmuCodeV,
    PONumber: PONumberV,
    uom: uomV,
    quantity: quantityV,
    sourceReference: sourceReferenceV,
    targetReference: targetReferenceV,
    blockID: blockIDV,
    itemName: itemNameV,
    itemType: itemTypeV,
  } = values;

  async function getData() {
    await axios
      .get(
        `mmu-creation-service/api/MMUCreation/v1/get`
      )
      .then((response) => {
        setmmuCodeList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });

    //setting ReqLineItemIdList=====================
    await axios
      .get(
        `item-master-service/api/itemMaster/v1/get?recStatus=Approved`
      )
      .then((response) => {
        setreqLineItemIdList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });

    //setting WHIDList===================
    await axios
      .get(
        `warehouse-master-service/api/WareHouseMaster/v1/get?recStatus=OPEN`
      )
      .then((response) => {
        setWHIDList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });

    //setting MRNNumberList===================
    await axios
      .get(
        `med-requisition-gen-service/api/MedRequisitionGen/v1/getRequisition?recStatus=OPEN`
      )
      .then((response) => {
        setMRNNumberList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });

    // //setting labtechnician===========
    // await axios
    //   .get(
    //     `https://apithf.softwaremathematics.com/profile-service-mongo/api/ProfileEntity/v2/get?roleCode=LAT4538`
    //   )
    //   .then((response) => {
    //     setLabTechnicianList(response.data.data.dtoList);
    //   })
    //   .catch((e) => {
    //     console.log("error", e);
    //   });
  }

  useEffect(() => {
    getData();
    setLoading(false);
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Stock MASTER</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsMRNNumber}
                value={MRNumberValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setMRNumberValue(newValue);

                  values.MRNumber = newValue?.MRNumber;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.MRNumber && touched.MRNumber}
                    success={MRNumber.length > 0 && !errors.MRNumber}
                    variant="standard"
                    label={MRNumber.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsWHID}
                value={WHIDValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setWHIDValue(newValue);

                  values.blockID = newValue?.WHMMUCODE;
                  values.WHID = newValue?.id;
                  values.sourceReference = newValue?.WHMMUCODE;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.WHID && touched.WHID}
                    success={WHID.length > 0 && !errors.WHID}
                    variant="standard"
                    label={WHID.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // {...defaultPropsflowType}
                value={flowTypeValue}
                options={["IN", "OUT"]}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setflowTypeValue(newValue);

                  values.flowType = newValue;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.flowType && touched.flowType}
                    success={flowType.length > 0 && !errors.flowType}
                    variant="standard"
                    label={flowType.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsreqLineItemId}
                value={reqLineItemIdValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setreqLineItemIdValue(newValue);

                  values.reqLineItemId = newValue?.MMUCODE;
                  values.uom = newValue?.UOM;
                  values.itemName = newValue?.name;
                  values.itemType = newValue?.type;

                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.reqLineItemId && touched.reqLineItemId}
                    success={reqLineItemId.length > 0 && !errors.reqLineItemId}
                    variant="standard"
                    label={reqLineItemId.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsmmuCode}
                value={mmuCodeValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setmmuCodeValue(newValue);

                  values.mmuCode = newValue?.code;
                  values.targetReference = newValue?.code;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.mmuCode && touched.mmuCode}
                    success={mmuCode.length > 0 && !errors.mmuCode}
                    variant="standard"
                    label={mmuCode.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={PONumber.type}
                label={PONumber.label}
                name={PONumber.name}
                value={PONumberV}
                placeholder={PONumber.placeholder}
                error={errors.PONumber && touched.PONumber}
                success={PONumberV.length > 0 && !errors.PONumber}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={quantity.type}
                label={quantity.label}
                name={quantity.name}
                value={quantityV}
                placeholder={quantity.placeholder}
                error={errors.quantity && touched.quantity}
                success={quantityV.length > 0 && !errors.quantity}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for StockInfo
StockInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default StockInfo;
