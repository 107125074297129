import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Newmmucreation page components
import TestInfo from "layouts/pages/Test/new test/componets/testInfo";

// new test layout schemas for form and form feilds
import validations from "layouts/pages/Test/new test/schemas/validations";
import form from "layouts/pages/Test/new test/schemas/form";
import initialValues from "layouts/pages/Test/new test/schemas/initialValues";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import MDAlert from "components/MDAlert";

function getSteps() {
  return ["Test Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <TestInfo formData={formData} />;
    default:
      return null;
  }
}

function Createtest() {
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [equipmentList, setEquipmentList] = useState([]);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const equipmentModel = {
    assetno: "",
    item: "",
    quantity: "",
  };

  function creatingEquipList(values) {
    console.log("before", values);
    for (let i = 0; i < values.investigationList.length; i++) {
      delete values.investigationList[i].action;
    }

    console.log("after", equipmentList);
  }
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  function myTimeoutForPageReload() {
    setTimeout(enablingSuccess, 2000);
  }
  function enablingSuccess() {
    window.location.reload();
  }
  const submitForm = async (values, actions) => {
    console.log("hii", values);
    creatingEquipList(values);
    const testObjectData = {
      approvedby: "",
      approveddate: "",
      classname: "",

      createdby:
        JSON.parse(localStorage.getItem("mmu-auth")).firstname +
        " " +
        JSON.parse(localStorage.getItem("mmu-auth")).lastname,
      createddate: "",

      documentofproof: "",
      id: null,
      investigationlist: values.investigationList,
      latlong: "",
      location: null,
      modifiedby: "",
      modifieddate: "",
      name: values.name,
      range: "",
      rangetype: "",
      reagent: "",
      recstatus: "OPEN",
      status: "Active",
      syncstatus: "SYNCED",
      tempid: "",
      testname: values.testname,
      uom: "",
      value: "",
    };

    await axios
      .post(
        "test-master-service/api/testMaster/v1/create",
        testObjectData
      )

      .then((response) => {
        console.log("gfgfgf", response);

        navigate("/page/component/test-list");
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data == null) {
          setErrorMsg("Something went wrong!!");
        } else {
          setErrorMsg(error.response?.data?.aceErrors[0].errorMessage);
        }

        setErrorAlert(true);
        myTimeoutForPageReload();
      });

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {errorAlert && (
        <MDAlert color="error" dismissible>
          {errorMsg}
        </MDAlert>
      )}
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      {/* <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper> */}
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Test Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Createtest;
