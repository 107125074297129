import swal from 'sweetalert';

const initialState = {
    auth: localStorage.getItem("mmu-auth") ? JSON.parse(localStorage.getItem("mmu-auth")) : {},
    Username: localStorage.getItem("profile-Name") ? JSON.parse(localStorage.getItem("profile-Name")) : {},
    code: localStorage.getItem("mmu-code") ? JSON.parse(localStorage.getItem("mmu-code")) : {},
    asso: localStorage.getItem("association") ? JSON.parse(localStorage.getItem("association")) : {},
    comp: localStorage.getItem("componet") ? JSON.parse(localStorage.getItem("componet")) : {},
    isAuthenticated: localStorage.getItem("mmu-auth") ? true : false,

    errors: {},

    loading: false
}
const AuthReducer = (state = initialState, action) => {

    switch (action.type) {
        case "LOGIN_PENDING":

            return { loading: true }

        case 'LOGIN_FULFILLED':
            swal("Welcome Back!", action.payload?.data?.dto.firstname + " " + action.payload?.data?.dto.lastname, "success");
            console.log("hellohello", action.payload?.data?.dto.firstname + " " + action.payload?.data?.dto.lastname);
            if (action.payload?.data?.dto !== "") {
                console.log("Login");
                localStorage.setItem('mmu-auth', JSON.stringify(action.payload?.data?.dto))
                localStorage.setItem('profile-Name', JSON.stringify(action.payload?.data?.dto.firstname + " "
                    + action.payload?.data?.dto.lastname))
                // localStorage.setItem('project-Detail', JSON.stringify(response.data.data.projectAggregation))
                return {

                    auth: action.payload?.data?.dto,
                    isAuthenticated: true,
                    loading: false

                }


            }
            else {
                swal("Alert!", "...Internal Server Error!");


                localStorage.removeItem('mmu-auth')
                localStorage.removeItem('profile-Name')
                localStorage.removeItem('mmu-code')
                localStorage.removeItem('project-Detail')
                localStorage.removeItem('association')
                localStorage.removeItem('componet')
                return {
                    auth: {},
                    Username: {},
                    code: {},
                    asso: {},
                    comp: {},
                    isAuthenticated: false
                }
            }
        case "LOGIN_REJECTED":
            //alert
            swal("Alert!", "...Incorrect Username or Password!");



            localStorage.removeItem('mmu-auth')
            localStorage.removeItem('profile-Name')
            localStorage.removeItem('mmu-code')
            localStorage.removeItem('project-Detail')
            localStorage.removeItem('association')
            localStorage.removeItem('componet')
            return {
                auth: {},
                Username: {},
                code: {},
                asso: {},
                comp: {},
                isAuthenticated: false,
                errors: action.payload,
                loading: false
            }

        case "LOGOUT_FULFILLED":
            localStorage.removeItem('mmu-auth')
            localStorage.removeItem('profile-Name')
            localStorage.removeItem('mmu-code')
            localStorage.removeItem('project-Detail')
            localStorage.removeItem('association')
            localStorage.removeItem('componet')
            
            return {
                auth: {},
                Username: {},
                code: {},
                asso: {},
                comp: {},
                isAuthenticated: false
            }
        default:
            return state
    }
}

export default AuthReducer
