/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-registration-form",
  formField: {
    mobileno: {
      name: "mobileno",
      label: "Mobile No",
      type: "number",
      errorMsg: "Mobile no is required.",
    },
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    // mmunumber: {
    //   name: "mmunumber",
    //   label: "Mmunumber",
    //   type: "text",
    //   errorMsg: "mmunumber is required.",
    // },
    village: {
      name: "village",
      label: "Village",
      type: "text",
      errorMsg: "Village is required.",
    },
    address: {
      name: "address",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
    },

    age: {
      name: "age",
      label: "Age",
      type: "number",
      errorMsg: "Age is required.",
    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "gender",
      errorMsg: "Gender  is required.",
      
    },
    marital: {
      name: "marital",
      label: "Marital",
      type: "marital",
      errorMsg: "Marital is required.",
      
    },
    education: {
      name: "education",
      label: "Education",
      type: "education",
      errorMsg: "eEducation is required.",
      
    },
    occupation: {
      name: "occupation",
      label: "Occupation",
      type: "occupation",
      errorMsg: "Occupation is required.",
      
    },
    rationcard: {
      name: "rationcard",
      label: "Ration Card",
      type: "rationcard",
      errorMsg: "Rationcard is required.",
      
    },
    disability: {
      name: "disability",
      label: "Disability",
      type: "disability",
      errorMsg: "Cisability is required.",
      
    },
    comdoc: {
      name: "comdoc",
      label: "COMP.DOC",
      type: "comdoc",
      errorMsg: "Com Doc is required.",
      
    },
    docno: {
      name: "docno",
      label: "Enter your doc.no",
      type: "docno",
      errorMsg: "Doc No is required.",
      
    },
    
    
    name2: {
      name: "name2",
      label: "Name",
      type: "name2",
      errorMsg: "name2  is required.",
      
    },
     
    
    mobileno2: {
      name: "mobileno2",
      label: "Mobile number",
      type: "number",
      errorMsg: "number is required.",
    },
   
    address2: {
      name: "address2",
      label: "Address",
      type: "text",
      errorMsg: "adress is required.",
    },
   
  
  },
};

export default form;
