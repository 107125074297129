// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewBenificery page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function RegistrationInfo({ formData }) {
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");

  const [value, setValue] = React.useState(null);
  const [roleList, setRoleList] = useState("");

  const [genderList, setGenderList] = useState("");
  const [maritalList, setMatitalList] = useState("");
  const [educationList, setEducationList] = useState("");
  const [occupationList, setOccupationList] = useState("");
  const [rationList, setRationList] = useState("");
  const [disabilityList, setDisabilityList] = useState("");
  const [comdocList, setComdocList] = useState("");
  const [genderValue, setGenderValue] = useState(null);
  const [maritalValue, setMaritalValue] = useState(null);
  const [educationValue, setEducationValue] = useState(null);
  const [occupationValue, setOccupationValue] = useState(null);
  const [rationcardValue, setRationcardValue] = useState(null);
  const [disabilityValue, setDisabilityValue] = useState(null);
  const [comdocValue, setComdocValue] = useState(null);


  const [mmucode, setMmucode] = useState(null);
  const [villageList, setVillageList] = useState("");
  const [villageValue, setVillageValue] = useState(null);
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")));

  const { formField, values, errors, touched } = formData;
  const defaultProps = {
    options: !roleList ? [{ name: "Loading...", id: 0 }] : roleList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function getMMUAssociation(profileId) {
    setLoading(true);

    await axios
      .get(

        `mmu-association-service/api/MMUAssociation/v1/getAllByProfileId/${profileId}`
      )
      .then((response) => {
        console.log(response.data.data.dtoList);
        if (response.data.data.dtoList.length > 0) {
          setMmucode(response.data.data.dtoList[0].mmucode);
          getScheduleVillage(response.data.data.dtoList[0].mmucode)
        }

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const defaultPropsVillage = {
    options: !villageList
      ? [
        { pincode: { village: "Loading...", id: "X" } }
      ]
      : villageList,
    getOptionLabel: (option) => option.pincode?.village?.name,
    isOptionEqualToValue: (option, value) => option.pincode?.id === value.pincode?.id,
  };
  const defaultPropsgender = {
    options: !genderList
      ? [
        { name: "Male", code: "X" },
        { name: "Female", code: "y" },
        { name: "Prefer not to tell", code: "Z" },
      ]
      : genderList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsMaritalstatus = {
    options: !maritalList
      ? [
        { name: "Married", code: "X" },
        { name: "Unmarried", code: "y" },
        { name: "Widow", code: "A" },
        { name: "Widower", code: "B" },
        { name: "Divocree", code: "C" },
        { name: "Separated", code: "D" },
        { name: "Other", code: "E" },
      ]
      : maritalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsEducation = {
    options: !educationList
      ? [
        { name: "Graduate", code: "X" },
        { name: "Under Graduate", code: "y" },
        { name: "PG", code: "A" },
        { name: "No Formal Edu", code: "B" },

      ]
      : educationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsOccupation = {
    options: !occupationList
      ? [
        { name: "Self-employed", code: "X" },
        { name: "Housewife", code: "y" },
        { name: "Agriculture", code: "Z" },
        { name: "Student", code: "1" },
        { name: "Govt Service", code: "2" },
        { name: "Pvt Service", code: "3" },


      ]
      : occupationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsRationcard = {
    options: !rationList
      ? [
        { name: "BPL", code: "X" },
        { name: "APL", code: "y" },
        { name: "Not Available", code: "A" },
        // { name: "No Formal Edu", code: "B" },

      ]
      : rationList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsDisability = {
    options: !disabilityList
      ? [
        { name: "No", code: "X" },
        { name: "Yes", code: "y" },
      ]
      : disabilityList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const defaultPropsComdoc = {
    options: !comdocList
      ? [
        { name: "Voter ID Card", code: "X" },
        { name: "Adhar Card", code: "y" },
        { name: "PAN Card", code: "1" },
        { name: "Ration Card", code: "2" },
        { name: "Drv License", code: "3" },
        { name: "Student ID Card", code: "4" },
        { name: "Immun Card", code: "5" },
      ]
      : comdocList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };



  const {
    mobileno,
    name,
    mmunumber,
    village,
    address,
    age,
    gender,
    marital,
    education,
    occupation,
    rationcard,
    disability,
    comdoc,
    docno
  } = formField;
  var {
    mobileno: mobilenoV,
    name: nameV,
    // mmunumber: mmunumberV,
    village: villageV,
    address: addressV,
    age: ageV,
    docno: docnoV

  } = values;


  async function getData() {
    await axios
      .get(
        `role-service-mongo/api/role/v2/get`
      )
      .then((response) => {
        setRoleList(response.data.data.dtoList);

        setLoading(false);
        console.log("====================================");
        console.log("de", defaultProps);
        console.log("====================================");
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getScheduleVillage(mmucode) {
    await axios
      .get(
        `project-service/api/Project/v1/getPinCode?mmu.code=${mmucode}`
      )
      .then((response) => {
        setVillageList(response.data.data.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  const handleChange = (event) => {
    roleV = event.target.value.value;
    console.log(roleV);
    setValue(event.target.value);
  };
  useEffect(() => {
    // getData();
    getMMUAssociation(loginUser.profileid);
  }, []);
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">New Beneficiary Registration</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <FormField
                type={mobileno.type}
                label={mobileno.label}
                name={mobileno.name}
                value={mobilenoV}
                placeholder={mobileno.placeholder}
                error={errors.mobileno && touched.mobileno}
                success={mobilenoV.length > 0 && !errors.mobileno}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>


            <Grid item xs={6} sm={3}>
              <FormField
                type="text"
                label="MMU"
                name="MMU"
                value={mmucode}
                disabled="true"
                placeholder="MMU"
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsVillage}
                value={villageValue}
                onChange={(event, newValue) => {
                  // handleChange(event);
                  console.log(newValue);
                  setVillageValue(newValue);

                  values.village = newValue?.pincode?.village?.name+"_"+newValue?.pincode?.village?.shortname;
                  
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.village && touched.village}
                    // success={villageV.length > 0 && !errors.village}
                    variant="standard"
                    label={village.label}
                  />
                )}
              />
              {/* <FormField
                type={village.type}
                label={village.label}
                name={village.name}
                value={villageV}
                placeholder={village.placeholder}
                error={errors.village && touched.village}
                success={villageV.length > 0 && !errors.village}
              /> */}

            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <FormField
                type={address.type}
                label={address.label}
                multiline
                name={address.name}
                value={addressV}
                placeholder={address.placeholder}
                error={errors.address && touched.address}
                success={addressV.length > 0 && !errors.address}

              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormField
                type={age.type}
                label={age.label}
                name={age.name}
                value={ageV}
                placeholder={age.placeholder}
                error={errors.age && touched.age}
                success={ageV.length > 0 && !errors.age}

              />

            </Grid>


            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsgender}
                value={genderValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setGenderValue(newValue);
                  values.mmunumber = mmucode;
                  values.gender = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.gender && touched.gender}
                    // success={gender.length > 0 && !errors.gender}
                    variant="standard"
                    label={gender.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsMaritalstatus}
                value={maritalValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setMaritalValue(newValue);

                  values.marital = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.marital && touched.marital}
                    // success={marital.length > 0 && !errors.marital}
                    variant="standard"
                    label={marital.label}
                  />
                )}
              />

            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsEducation}
                value={educationValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setEducationValue(newValue);

                  values.education = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.education && touched.education}
                    // success={education.length > 0 && !errors.education}
                    variant="standard"
                    label={education.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsOccupation}
                value={occupationValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setOccupationValue(newValue);

                  values.occupation = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.occupation && touched.occupation}
                    // success={occupation.length > 0 && !errors.occupation}
                    variant="standard"
                    label={occupation.label}
                  />
                )}
              />

            </Grid>


            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsRationcard}
                value={rationcardValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setRationcardValue(newValue);

                  values.rationcard = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.rationcard && touched.rationcard}
                    // success={rationcard.length > 0 && !errors.rationcard}
                    variant="standard"
                    label={rationcard.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsDisability}
                value={disabilityValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDisabilityValue(newValue);

                  values.disability = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.disability && touched.disability}
                    // success={disability.length > 0 && !errors.disability}
                    variant="standard"
                    label={disability.label}
                  />
                )}
              />

            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Autocomplete
                {...defaultPropsComdoc}
                value={comdocValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setComdocValue(newValue);

                  values.comdoc = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.comdoc && touched.comdoc}
                    // success={comdoc.length > 0 && !errors.comdoc}
                    variant="standard"
                    label={comdoc.label}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormField
                type={docno.type}
                label={docno.label}
                multiline
                name={docno.name}
                value={docnoV}
                placeholder={docno.placeholder}
              />

            </Grid>
          </Grid>

        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for RegistrationInfo
RegistrationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RegistrationInfo;
